import PropTypes from "prop-types"
import { createContext } from "react";
import { useQuestionEditor } from "../hooks/useQuestionEditor";

// Vytvoření kontextu
export const QuestionEditorContext = createContext({});

// Provider
export function QuestionEditorProvider({ children, questionId }) {
  const data = useQuestionEditor(questionId);

  return (
    <QuestionEditorContext.Provider value={data}>
      {children}
    </QuestionEditorContext.Provider>
  );
}

QuestionEditorProvider.propTypes = {
  children: PropTypes.node.isRequired,
  questionId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
};