import React from 'react';
import Icon from '@mdi/react';
import { getIcon } from '../functions';
import PropTypes from 'prop-types';

const MDIcon = React.forwardRef(({ path, className, ...rest }, ref) => {
    let _icon = getIcon(path);
    let _icon_class = className;

    console.log(`[ICON]`, path, _icon, _icon_class);

    if (!_icon) return null;

    return <Icon ref={ref} className={`mdicon ${_icon_class}`} mdi path={_icon} {...rest} />
});


MDIcon.displayName = 'MDIcon';

MDIcon.propTypes = {
    path: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
};

export default MDIcon;