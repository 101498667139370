import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Divider, Unstable_Grid2 as Grid, LinearProgress, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '@/layouts/dashboard/layout';
import REST from '../utils/rest';
import { useCallback, useEffect, useState } from 'react';
import { toastError, toastSuccess } from '../utils/toasts';
import { useNavigate } from 'react-router-dom';
import DateRangePickerEmbeded from '../components/date-range-picker';
import { useTranslation } from 'react-i18next';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import SimpleBar from 'simplebar-react';

const DateRange = ({ callback }) => {
    return <DateRangePickerEmbeded callback={callback} />
}

const DataTable = ({ title, users, t }) => {

    const data = users || []

    return <Grid xs={12}>
        <Card sx={{ height: '100%' }}>
            <CardHeader title={title} />
            <SimpleBar>
                <Box sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t(`test_evaluation.user`)}
                                </TableCell>
                                <TableCell>
                                    {t(`test_evaluation.successful_attempts`)}
                                </TableCell>
                                <TableCell>
                                    {t(`test_evaluation.unsuccessful_attempts`)}
                                </TableCell>
                                <TableCell>
                                    {t(`test_evaluation.best_result`)}
                                </TableCell>
                                <TableCell>
                                    {t(`test_evaluation.average_result`)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((rec, index) =>
                                <TableRow
                                    hover
                                    key={index}
                                    // style={{ cursor: `pointer` }}
                                >
                                    <TableCell>
                                        {rec.USER_NAME} {rec.USER_SURNAME}
                                    </TableCell>
                                    <TableCell>
                                        {rec.ATTEMPTS_SUCCESSFUL}
                                    </TableCell>
                                    <TableCell>
                                        {rec.ATTEMPTS_UNSUCCESSFUL}
                                    </TableCell>
                                    <TableCell>
                                        {rec.ATTEMPT_BEST}%
                                        <LinearProgress
                                            title={`${rec.SUC_PERCENTAGE_BEST}%`}
                                            value={rec.ATTEMPT_BEST}
                                            variant="determinate"
                                            thickness={4}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {rec.ATTEMPT_AVG}%
                                        <LinearProgress
                                            title={`${rec.SUC_PERCENTAGE_BEST}%`}
                                            value={rec.ATTEMPT_AVG}
                                            variant="determinate"
                                            thickness={4}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {(!data || data?.length === 0) && <Typography align='center' style={{ width: `100%`, padding: 32 }} >{t(`general.no_records`)}</Typography>}
                </Box>
            </SimpleBar>
            <Divider />
            <CardActions sx={{ justifyContent: 'flex-end' }}>
            </CardActions>
        </Card>
    </Grid>
}

const Page = () => {

    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState([null, null])
    const [data, setData] = useState(null);
    const { t, i18n } = useTranslation()

    const getData = useCallback(async (export_data) => {
        console.log(`getData`, dateRange, dateRange?.[0] && dateRange?.[1])
        if (dateRange?.[0] && dateRange?.[1]) {
            const params = { from: new Date(dateRange?.[0]).getTime(), to: new Date(dateRange?.[1]).getTime() }
            const queryParams = `?${Object.keys(params)?.map(key => `${key}=${params[key]}`).join(`&`)}`
            console.log({ params, queryParams })
            const [success, newdata] = await REST.GET(`user_evaluation${queryParams}${export_data ? `&export=1` : ``}`, { download: export_data })
            if (!success) toastError(`${t(`general.error`)} ${t(`general.loading_data`)}`)
            if (export_data) return toastSuccess(`${t(`user_evaluation.export`)} ${t(`user_evaluation.successful`)}`)
            setData(newdata)
        }
    }, [dateRange])

    useEffect(() => {
        console.log(`date range changed`, dateRange)
        getData()
    }, [dateRange])

    return <Box
        component="main"
        sx={{
            flexGrow: 1,
            py: 4
        }}
    >
        <Container>

            {/* TITLE */}
            <Box display={`flex`} justifyContent={`space-between`} flexWrap={"wrap"} marginBottom={2}>
                <Typography variant="h2" marginBottom={0}>{t(`user_evaluation.title`)}</Typography>
                <Button
                    sx={{ fontSize: 32 }}
                    onClick={() => getData(true)}
                    startIcon={(
                        <SvgIcon sx={{ fontSize: 32 }}>
                            <ArrowUpTrayIcon sx={{ fontSize: 32 }} />
                        </SvgIcon>
                    )}>
                    {t(`user_evaluation.export`)}
                </Button>
            </Box>

            <Grid container spacing={3} >
                <Grid>
                    <DateRange callback={setDateRange} />
                </Grid>
            </Grid>

            <Grid container spacing={3} >
                <DataTable title={`${t(`user_evaluation.title`)}`} users={data?.users} navigate={navigate} {...({ t, i18n })} />
            </Grid>

        </Container>
        {/* <Placeholder /> */}
    </Box >
}

Page.getLayout = (page) => (
    <DashboardLayout>
        {page}
    </DashboardLayout>
);

export default Page;
