import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import defaultTranslations from './locales/cs/translation.json'; // Pro češtinu
import REST from './utils/rest';


const bundledResources = {
  cs: { translation: defaultTranslations } // Vložení výchozích překladů
};

i18n
  //.use(Backend)
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //lng: 'cs', // toto způsobuje že se načte vždy jako první čeština pro tabulkový framework - vypadá to že zakomentování nic nerozbije tak zatím vyřešeno takto
    fallbackLng: 'cs',
    //resources: {
    //  cs: { translation: defaultTranslations } // Vložení výchozích překladů
    //},
    //backend: {
    //  loadPath: REST.URL(`strings/{{lng}}.json`),
    //},
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend(bundledResources)
      ],
      backendOptions: [{
        loadPath: REST.URL(`strings/{{lng}}.json`)
      }]
    },
    interpolation: {
      escapeValue: false
    }
  });
export default i18n;
