import PropTypes from "prop-types"
import { Box, Button, Tab, Tabs, Typography } from "@mui/material"
import { Add } from "@mui/icons-material"

const TabItem = ({ id, label, icon, iconPosition = "start", ...restProps }) => {
  return <Tab
    value={id}
    label={label}
    icon={icon}
    iconPosition={iconPosition}
    {...restProps}
  />
}

TabItem.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  iconPosition: PropTypes.string,
}


const TabList = ({ items, value, onChange, addTab, TabItemProps }) => {
  return <Box display="flex">
    <Tabs
      value={value}
      onChange={(event, newValue) => onChange(event, newValue)}
      variant="scrollable"
    >
      {items.map((tab, index) => <TabItem key={index} {...TabItemProps} {...tab} />)}
    </Tabs>
    {addTab ? <Button sx={{ ml: "auto" }} onClick={addTab}><Add /></Button> : undefined}
  </Box>
}

TabList.propTypes = {
  addTab: PropTypes.func,
  items: PropTypes.arrayOf(TabItem),
  onChange: PropTypes.func,
  value: PropTypes.any,
  TabItemProps: PropTypes.object
}

export default TabList;