import { DragHandleRounded, Edit, RemoveCircleOutline } from '@mui/icons-material'
import { Box, Dialog, IconButton, MenuItem, Select, Typography } from '@mui/material'
import { useCallback } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Droppable } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

const SelectItem = ({ index, options, openDialog, innerRef, dragHandleProps, removeSelect, handleCorrectChange, ...otherProps }) => {
  const menuList = options?.map(i => <MenuItem key={i?.id} value={i?.new_id || i?.id} selected={Boolean(i?.correct)}>{i?.text}</MenuItem>)
  const { t, i18n } = useTranslation()
  const correctOption = options?.find(i => i?.correct);
  const correctId = correctOption?.new_id || correctOption?.id;

  return (
    <Box mt={2} ref={innerRef}{...otherProps}>
      <Box display="flex" alignItems="center" gap={1}>
        <IconButton disableRipple {...dragHandleProps} >
          <DragHandleRounded />
        </IconButton>
        <Typography>{index + 1}</Typography>
        <Select
          placeholder='Test'
          size="small"
          sx={{ minWidth: "100px" }}
          value={correctId || 0}
          onChange={(e) => {
            console.log("e", e.target);
            handleCorrectChange(e.target.value)
          }}
        >
          <MenuItem disabled value={0}>
            {t("general.choose")}
          </MenuItem>
          {menuList || ""}
        </Select>
        <IconButton onClick={() => openDialog(index)}>
          <Edit />
        </IconButton>
        <IconButton color="error" onClick={() => removeSelect(index)}>
          <RemoveCircleOutline />
        </IconButton>
      </Box>
    </Box >
  )
}

const SelectsManager = ({ selects, orderSelect, openDialog, removeSelect, handleCorrectChange }) => {
  const { t, i18n } = useTranslation()


  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;

    const currentIndex = result.source.index;
    const newIndex = result.destination.index;

    orderSelect(currentIndex, newIndex);
  }, [orderSelect]);


  const isEmpty = selects.length === 0;

  return <Box><DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId='selects-droppable-1'>
      {(provided) => (
        <Box {...provided.droppableProps} ref={provided.innerRef}>
          {isEmpty && <Typography p={2} variant='body1' textAlign="center" color="GrayText">{t(`question_editor.add_selection`)}</Typography>}
          {selects.map((options, index) => (
            <Draggable key={index} draggableId={String(index)} index={index}>
              {(dragProvided) => (
                <SelectItem
                  key={index}
                  index={index}
                  options={options.options}
                  handleCorrectChange={(correctId) => {
                    console.log("correctId", correctId, index);
                    handleCorrectChange(index, correctId);
                  }}
                  openDialog={openDialog}
                  removeSelect={removeSelect}
                  innerRef={dragProvided.innerRef}
                  {...dragProvided.draggableProps}
                  dragHandleProps={dragProvided.dragHandleProps}
                />
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  </DragDropContext>
  </Box>;
}

export default SelectsManager