import { useCallback, useEffect, useMemo, useState } from "react";
import REST from "../utils/rest";
import { toastError, toastSuccess } from "../utils/toasts";
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Divider, Grid, IconButton, LinearProgress, Paper, Stack, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import { ArrowRightIcon, CheckIcon, DocumentIcon, DocumentPlusIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Icon } from "./icon";
import { neutral } from "../theme/colors";
import { useDropzone } from 'react-dropzone'
import { useFullscreen } from "../contexts/FullscreenContext";
// import { t } from "i18next";
import { visuallyHidden } from '@mui/utils';
import { useFileDrop } from "../contexts/FileDropContext";
import FileManagerItem from "./file-manager-table-item";
import useEventListener from "../hooks/useEventListener";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
    console.log(`getComparator`, { a, b, orderBy })
    if (!orderBy) return null
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    console.log(`getComparator`, { order, orderBy })
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const FileManagerTable = ({
    // data, selected,
    // setSelected,
    onClose,
    dialog,
    pickOne,
    checked,
    maxHeight,
    width,
    search,
    deletable
}) => {

    console.log(`file manager checked props`, checked)


    const { t, i18n } = useTranslation()
    const [data, setData] = useState(null)
    const [selected, setSelected] = useState([]);

    const getData = useCallback(async () => {
        const [success, newdata] = await REST.GET(`extra_files`)
        if (!success) return toastError()
        console.log(`file manager get data`, newdata.checked)
        setData(newdata)
        if (checked) setSelected(checked)
    }, [])

    const recordEdited = useCallback(async (record) => {
        const [success, newdata] = await REST.PUT(`extra_files`, record)
        if (!success) return toastError()
        toastSuccess(t(`file_manager.edited`))
        setData(newdata)
    }, [])

    const recordDeleted = useCallback(async (record) => {
        const [success, newdata] = await REST.DELETE(`extra_files`, record)
        if (!success) return toastError()
        toastSuccess(t(`file_manager.deleted`))
        setData(newdata)
    }, [])

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        console.log(`file manager checked`, checked)
    }, [checked])

    useEventListener(`file_manager_refresh`, () => getData())

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const filtered_data = useMemo(() => data?.filter(x => {
        if (!search || search === "") return true
        return x?.EXTRA_FILE_NAME?.toLowerCase().includes(search?.toLowerCase())
    }), [data, search])

    const rows = useMemo(() => filtered_data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort(getComparator(order, orderBy)), [filtered_data, order, orderBy, page, rowsPerPage]);
    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rows?.length) : 0;

    console.log("[FM]", { data, filtered_data, rows, emptyRows, page, rowsPerPage })

    const createSortHandler = (property) => (event) => {
        console.log(`sort click`, property, event)
        handleRequestSort(event, property);
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.EXTRA_FILE);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleSelectItem = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];


        if (pickOne) {
            if (selectedIndex === -1)
                newSelected = [name]
            else if (selectedIndex === 0)
                newSelected = []
            return setSelected(newSelected);
        }


        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        console.log(`handleSelectItem`, { selectedIndex, name, newSelected, selected })

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const columns = {
        EXTRA_FILE_NAME: t(`general.name`),
        FILE_SIZE: t(`general.size`),
        DATE_CREATED: `${t(`general.date`)} ${t(`general.of_creation`)}`,
        DATE_MODIFIED: `${t(`general.date`)} ${t(`general.of_change`)}`,
    }

    const handleRequestSort = (event, property) => {
        // console.log({ isAsc, orderBy, order, property })
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return <>
        <TableContainer style={{ maxHeight: maxHeight || 600, width: width || 800 }}>
            <Table>
                <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
                    <TableRow>
                        {dialog && <TableCell padding="checkbox">
                            {!pickOne && <Checkbox
                                color="primary"
                                indeterminate={selected?.length > 0 && selected?.length < rows?.length}
                                checked={rows?.length > 0 && selected?.length === rows?.length}
                                onChange={handleSelectAll}
                                inputProps={{
                                    'aria-label': 'select all desserts',
                                }}
                            />}
                        </TableCell>}
                        <TableCell>
                        </TableCell>
                        {Object.keys(columns)?.map((col, id) => {
                            return <TableCell
                                key={id}
                                sortDirection={orderBy === col ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy == col}
                                    direction={orderBy == col ? order : 'asc'}
                                    onClick={createSortHandler(col)}
                                >
                                    {columns[col]}
                                    {orderBy == col ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        })}
                        {deletable && <TableCell>
                        </TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((rec, index) => <FileManagerItem
                        selected={selected}
                        dialog={dialog}
                        key={index}
                        rec={rec}
                        handleSelectItem={handleSelectItem}
                        recordEdited={recordEdited}
                        recordDeleted={recordDeleted}
                        deletable={deletable}
                    />)}
                    {emptyRows > 0 && [...new Array(emptyRows)].map((x, i) => <TableRow key={i} style={{ height: 68, }}><TableCell colSpan={6} /></TableRow>)}
                </TableBody>
            </Table>
            {rows && <TablePagination
                sx={{ position: "sticky", bottom: 0, left: 0, zIndex: 1, backgroundColor: neutral[50] }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t(`general.rows_per_page`)}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t(`general.of`)} ${count}`}
            />}
        </TableContainer>
        <Divider />
        {dialog && <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
                color="inherit"
                endIcon={(

                    <SvgIcon fontSize="small">
                        <XMarkIcon />
                    </SvgIcon>
                )}
                size="medium"
                variant="text"
                onClick={() => onClose && onClose()}
            >
                {t(`general.cancel`)}
            </Button>
            <Button
                disabled={!selected?.length > 0}
                color="primary"
                endIcon={(
                    <SvgIcon fontSize="small">
                        <CheckIcon />
                    </SvgIcon>
                )}
                size="medium"
                variant="text"
                onClick={() => onClose && onClose(data?.filter(x => selected?.includes(x?.EXTRA_FILE)))}
            >
                {t(`general.accept`)}
            </Button>
        </CardActions>}
    </>
}

export default FileManagerTable;