import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { openTest } from "../services/openTest";
import { useTestContext } from '../contexts/TestContext';

function useEnterTestKit() {
  const navigate = useNavigate();
  const { setLoading } = useTestContext();

  const enterTest = useCallback(async (TEST_KIT_ID) => {
    setLoading(true);
    const attempt_id = await openTest(TEST_KIT_ID)
    console.log(`enterTest`, attempt_id)
    if (attempt_id)
      navigate(`/test/${attempt_id}`)
  }, []);

  return enterTest;
}

export default useEnterTestKit;