import { useEffect, useState } from 'react';
import {
  Box,
  SvgIcon,
  Typography
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import PsychologyIcon from '@mui/icons-material/Psychology';

import Loader from '../../components/Loader';
import Header from './Header/Header';
import Question from './Question/Question';
import TestIntro from './StudyMaterial/TestIntro';
import StudyMaterialDrawer from './StudyMaterial/TestIntroDrawer';
import Footer from './Footer/Footer';
import FinishPage from './Finish';
import ErrorPage from '../error_page';


import useTestLogic from '../../hooks/useTestLogic';
import { useTestContext } from '../../contexts/TestContext';
import { useParams } from 'react-router-dom';

const TestPage = () => {
  const {
    progress,
    loading,
    currentQuestion,
    test,
    isAnswered,
    isSuccess,
    error,
    lives,
    hasAnswer
  } = useTestContext();
  const { testId } = useParams();
  const { submitAnswer, nextQuestion, reset } = useTestLogic();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(prev => !prev);

  console.log('error', error)
  useEffect(() => {
    console.log("[TEST] enter");
    return () => {
      console.log("[TEST] leave");
      reset();
    }
  }, [testId])

  // Chybový stav
  if (error) {
    return <ErrorPage title={error} />
  }

  // Načítání
  if ((!test || !progress) && loading) {
    return <Loader loading={loading} delay={false} />;
  }

  // Nemáme data - něco je špatně
  if (!test || !progress) {
    return <ErrorPage title={"Něco se nepovedlo ?"} />
  }

  // Intro fáze
  if (progress.phase === "intro") {
    return (<>
      <Loader loading={loading} delay={false} />
      <TestIntro intro={true} test={test} confirm={nextQuestion} />
    </>);
  }

  // Konečná fáze
  if (progress.phase === "finished" || progress.phase === "failed")
    return (<>
      <Loader loading={loading} delay={false} />
      <FinishPage data={{ test, progress }} />
    </>);

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", flex: 1, minWidth: "100%", flexDirection: "column", justifyContent: "space-between" }}>
      {/* Studijní materiály k testu */}
      <StudyMaterialDrawer
        isDrawerOpen={isDrawerOpen}
        setDrawerOpen={setIsDrawerOpen}
        test={test}
        intro={false}
        toggleDrawer={toggleDrawer}
      />

      {/* Loader pro načítání */}
      <Loader loading={loading} />

      {/* Informace o testu */}
      <Header
        currentNumber={progress?.actualQuestion}
        totalQuestions={test?.totalQuestions}
        totalLives={test?.totalLives}
        remainingLives={lives}
        toggleDrawer={toggleDrawer}
      />

      {/* Zobrazení otázky */}
      <Question
        currentQuestion={currentQuestion}
        progress={progress}
      />

      <Box>
        <AdditionalInfo
          additional_fact={currentQuestion.additional_fact}
          additional_info={currentQuestion.additional_info}
        />
        {/* Informace o průběhu testu a zbývajících pokusech */}
        <Footer
          onConfirm={() => submitAnswer()}
          isAnswered={isAnswered}
          nextQuestion={nextQuestion}
          isSuccess={isSuccess}
          isLoading={loading}
          hasAnswer={hasAnswer}
        />
      </Box>
    </Box>
  );
}

const AdditionalInfo = ({ additional_info, additional_fact }) => {
  return <Box>
    {additional_info?.trim() && <Info backgroundColor="rgba(255, 165, 0, 0.1)" icon={<HelpIcon />} text={additional_info} />}
    {additional_fact?.trim() && <Info backgroundColor="rgba(255, 165, 0, 0.1)" icon={<PsychologyIcon />} text={additional_fact} />}
  </Box>
}

const Info = ({ backgroundColor, text, icon }) => {

  return (
    <Box sx={{ background: backgroundColor, padding: 2, display: "flex", alignItems: "center" }}>
      <SvgIcon sx={{ mr: 2, color: "orange", fontSize: "2rem" }} color='orange'>{icon}</SvgIcon>
      <Typography variant="body1">{text}</Typography>
    </Box>
  )
}

export default TestPage;