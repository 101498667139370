import { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
// import { Portal } from "./portal";
import { Backdrop, Card, CardActions, Dialog, Divider, Fab, IconButton, Portal, Typography } from "@mui/material";
import { useFileManager } from "../contexts/FileManagerContext";
import FileManager from "./file-manager";

const FileManagerDialog = () => {
    const { isOpen, data, open, close, pickOne, checked } = useFileManager()

    useEffect(() => {
        //console.log(`[file manager dialog]`, { isOpen, data, open, close })
    }, [isOpen])

    if (!isOpen) return null;
    return <Portal>
        <Dialog
            open={true}
            maxWidth={false}
            onClose={() => close()}
        >
            <FileManager dialog pickOne={pickOne} checked={checked} onClose={(res) => close(res)} />
        </Dialog>
    </Portal>
}

export default FileManagerDialog;