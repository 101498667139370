import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { Alert, Backdrop, Button, Card, CardActions, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, IconButton, Portal, SvgIcon, Typography } from "@mui/material";
import { neutral } from "../theme/colors";
import { useDropzone } from "react-dropzone";
import REST from "../utils/rest";
import { useFileDrop } from "../contexts/FileDropContext";
// import { t } from "i18next";
import { Icon } from "./icon";
import { DocumentPlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { toastDismiss, toastError, toastLoading, toastSuccess } from "../utils/toasts";
import { broadcastEvent } from "../utils/events";
import { useTranslation } from "react-i18next";

const FileDropDialog = () => {
    const [error, setError] = useState(null);
    const { isOpen, close, path } = useFileDrop()
    const { t } = useTranslation()

    //console.log(`FileDropDialog`, { isOpen, path })

    const onClose = () => {
        close();
        setError(null);
    }

    const onFileDrop = useCallback(async (acceptedFiles) => {

        const formData = new FormData();
        acceptedFiles.forEach((file) => {
            console.log(`files`, { file })
            formData.append('files', file);
        });
        console.log(`uploading files`, { acceptedFiles, formData })
        const loading = toastLoading(t(`file_manager.uploading_files`))
        const res = await fetch(REST.URL(path || `ajax/extra_files`), { method: `POST`, body: formData, credentials: "include", })
        const result = await res.json();
        if (loading) toastDismiss(loading)

        console.log(res, result)
        if (result?.Code === 100) {
            if (result?.Results?.message)
                toastSuccess(result?.Results?.message)
            broadcastEvent(`file_manager_refresh`)
            return close()
        }

        if (result.Code !== 100) {
            const message = result?.message || result?.Message;
            setError(message);
            return toastError(message)
        }

        if (result?.message)
            return toastError(result?.message)

    }, [path])

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop: onFileDrop,
        noClick: true,
    })

    if (!isOpen) return null;

    return <Portal>
        <Dialog
            open={true}
            maxWidth={false}
            onClose={onClose}
        >
            <DialogContent>
                {error ? <Alert variant="filled" severity="error" sx={{ mb: 2 }}>
                    <div style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: error }}></div>
                </Alert> : null}
                <div {...getRootProps()}>
                    <div
                        style={{
                            padding: 32,
                            borderRadius: 20,
                            background: neutral[50],
                            display: `flex`,
                            alignItems: `center`,
                        }}
                    >
                        <SvgIcon
                            style={{
                                fontSize: 100,
                                margin: `auto`,
                                color: neutral[500],
                                marginRight: 30
                            }}
                        >
                            <Icon path={`ArrowDownTrayIcon`} />
                        </SvgIcon>
                        <Typography variant="h5" color={neutral[600]}>{t(`file_manager.drop_files_here`)}</Typography>
                    </div>
                    <input {...getInputProps()} />
                    <div style={{ visibility: isDragActive ? `hidden` : `visible` }}>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ display: `flex`, justifyContent: `space-between` }}>
                <Button
                    color="inherit"
                    endIcon={(
                        <SvgIcon fontSize="small">
                            <XMarkIcon />
                        </SvgIcon>
                    )}
                    size="medium"
                    variant="text"
                    onClick={() => close()}
                >
                    {t(`general.cancel`)}
                </Button>
                <Button
                    color="primary"
                    endIcon={(
                        <SvgIcon fontSize="small">
                            <DocumentPlusIcon />
                        </SvgIcon>
                    )}
                    size="medium"
                    variant="text"
                    onClick={open}
                >
                    {t(`file_manager.choose`)} {t(`file_manager.files`)}
                </Button>
            </DialogActions>
        </Dialog>
    </Portal>
}

export default FileDropDialog;