import { useRef, useState } from "react";
import PropTypes from 'prop-types';
// import { Portal } from "./portal";
import { Backdrop, Card, CardActions, Divider, IconButton, Portal, Typography } from "@mui/material";
import { Icon } from "./icon";
import { usePdf } from '@mikecousins/react-pdf';
import '../styles/media-view.scss'
import { useFullscreen } from "../contexts/FullscreenContext";
import { useTranslation } from "react-i18next";


const MediaView = (props) => {

    const { open, close } = useFullscreen()

    const {
        title,
        img,
        video,
        file,
        download
    } = props;


    const PDFContent = () => {

        const [page, setPage] = useState(1);
        const { t, i18n } = useTranslation()

        const canvasRef = useRef(null);
        const { pdfDocument } = usePdf({
            file: file,
            page,
            canvasRef,
            onDocumentLoadFail: (x) => console.log(`[PDF] onDocumentLoadFail`, x),
            onPageLoadFail: (x) => console.log(`[PDF] onPageLoadFail`, x),
            onDocumentLoadSuccess: (x) => console.log(`[PDF] onDocumentLoadSuccess`, x),
            onPageLoadSuccess: (x) => console.log(`[PDF] onPageLoadSuccess`, x),
            onPageRenderFail: (x) => console.log(`[PDF] onPageRenderFail`, x),
            onPageRenderSuccess: (x) => console.log(`[PDF] onPageRenderSuccess`, x),
        });

        return <>
            {!pdfDocument && <span>{t(`general.loading`)}...</span>}
            <div className="pdf-wraper">
                {pdfDocument && <canvas onClick={() => open(props)} className="pdf-canvas" style={{ width: `100%`, height: `unset`, cursor: `pointer` }} ref={canvasRef} />}
            </div>
            <Divider />
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <div>
                    {title && <Typography variant="h6">{title}</Typography>}
                </div>
                <div>
                    {download ? <IconButton href={file} target="_blank" color="inherit">
                        <Icon path={`ArrowDownTrayIcon`} />
                    </IconButton> : null}
                    <IconButton disabled={page === 1} onClick={() => setPage(page - 1)} color="inherit">
                        <Icon path={`ArrowUturnLeftIcon`} />
                    </IconButton>
                    <IconButton disabled={page === pdfDocument?.numPages} onClick={() => setPage(page + 1)} color="inherit">
                        <Icon path={`ArrowUturnRightIcon`} />
                    </IconButton>
                    <IconButton disabled={!pdfDocument} onClick={() => open(props)} color="inherit">
                        <Icon path={`ArrowsPointingOutIcon`} />
                    </IconButton>
                </div>
            </CardActions>
        </>
    }

    return <Card>
        {img && <img style={{ width: `100%`, objectFit: `contain`, cursor: `pointer` }} src={img} alt={title} onClick={() => open(props)} />}
        {video && <video controls style={{ width: `100%`, cursor: `pointer` }} src={video} alt={title} /* onClick={() => open(props)} */ />}
        {file && <PDFContent />}
        {(!file && title) && <>
            <Divider />
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <div>
                    {title && <Typography variant="h6">{title}</Typography>}
                </div>
                <div>
                    {!video && <IconButton onClick={() => open(props)} color="inherit">
                        <Icon path={`ArrowsPointingOutIcon`} />
                    </IconButton>}
                </div>
            </CardActions>
        </>}
    </Card>
};

MediaView.propTypes = {
    title: PropTypes.string,
    img: PropTypes.string,
    video: PropTypes.string,
    file: PropTypes.string,
    googleSheets: PropTypes.bool,
};

export default MediaView;