import PropTypes from "prop-types"
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { List, ListItem, ListItemIcon, ListItemText, Typography, styled } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const DragIcon = styled(DragHandleIcon)({
  marginRight: 8,
});

const StyledListItem = styled(ListItem)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  marginBottom: '8px',
  backgroundColor: '#f5f5f5',
  '&:hover': {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  },
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const OrderAnswer = ({ progress, answers: defaultItems, setAnswer }) => {
  const [items, setItems] = useState(defaultItems);
  const [draggedItems, setDraggedItems] = useState(null);

  console.log("progress", progress)
  useEffect(() => {
    setItems(defaultItems);
  }, [defaultItems])

  const onDragEnd = (result) => {
    if (!result.destination) {
      setDraggedItems(null);
      return;
    }

    const reorderedItems = reorder(items, result.source.index, result.destination.index);
    setItems(reorderedItems);
    setDraggedItems(null);

    setAnswer(reorderedItems.map(i => i.id));
  };

  const onDragUpdate = (update) => {
    if (!update.destination) {
      return;
    }

    const reorderedItems = reorder(items, update.source.index, update.destination.index);
    setDraggedItems(reorderedItems);
  };

  const getItemsForRendering = () => {
    return draggedItems || items;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {getItemsForRendering().map((item, index) => (
              <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                  <StyledListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ListItemIcon style={{ minWidth: '30px', textAlign: 'center' }}>
                      {index + 1}
                      {typeof item.answered === "number" && <Typography color={index === item.answered ? "success.main" : "error"} fontWeight="bold">({item.answered + 1})</Typography>}
                    </ListItemIcon>
                    <ListItemIcon>
                      <DragIcon />
                    </ListItemIcon>
                    <ListItemText primary={item.description} />
                  </StyledListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

OrderAnswer.propTypes = {
  answers: PropTypes.array,
  setAnswer: PropTypes.func
}

export default OrderAnswer;
