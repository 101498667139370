import toast from 'react-hot-toast';
import i18next from 'i18next';

/**
 * Base toast
 * @param {*} msg 
 * @param {*} props 
 */
export function toastShow(msg, props) {
    toast(msg, props);
}

/**
 * Custom JSX toast
 * @param {*} msg 
 * @param {*} props 
 */
export function toastCustom(msg, props) {
    toast.custom(msg, props);
}

/**
 * Error toast
 * @param {*} msg 
 * @param {*} props 
 */
export function toastError(msg, props) {
    toast.error(msg, props);
}

/**
 * Success toast
 * @param {*} msg 
 * @param {*} props 
 */
export function toastSuccess(msg, props) {
    toast.success(msg, props);
}

/**
 * Loading toast
 * @param {Promise} promise 
 * @param {*} msg 
 * @param {*} props 
 */
export function toastLoadingPromise(promise, msg, props) {
    return new Promise((resolve, reject) => {
        toast.promise(promise, {
            loading: msg || i18next.t(`general.loading`),
            success: (data) => resolve(data),
            error: (err) => reject(err),
        }, props);
    })
}

/**
 * Loading toast
 * @param {Promise} promise 
 * @param {*} msg 
 * @param {*} props 
 */
export function toastLoading(msg) {
    return toast.loading(msg || i18next.t(`general.loading`));
}

export function toastDismiss(t) {
    return toast.dismiss(t);
}
