import PropTypes from "prop-types"
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import FormTextField from './Form/TextField';
import QuestionImageCard from './QuestionImageCard';
import AnswersCard from './AnswerTypes/AnswersCard';
import { useTranslation } from "react-i18next";

const QuestionForm = ({ name, control, questionType, watch, errors, setValue, removeVariant }) => {

  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="h6">{t(`question_editor.question_text_main`)}</Typography>
              <FormTextField
                label={t(`general.question`)}
                name={`${name}.assignment`}
                control={control}
                inputProps={{
                  helperText: t(questionType["input.assignment.helperText"])
                }}
              />
              <Typography variant="h6" mt={2}>{t(`general.additional_info`)}</Typography>
              <FormTextField
                label={t(`general.key_point`)}
                name={`${name}.additional.keyPoint`}
                control={control}
              />
              <FormTextField
                label={`${t(`general.did_you_know`)}?`}
                name={`${name}.additional.fact`}
                control={control}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box display="flex" gap={3} py={2}>
        {questionType.options.questionImage && <Box flex={1}>
          <QuestionImageCard control={control} name={name} />
        </Box>}
        <Box flex={3} gap={1}>
          <AnswersCard
            name={name}
            type={questionType}
            data={{
              //settings: data.settings,
              //answers: variant.answers,
              name,
              control,
              watch,
              errors,
              setValue
            }}
          />
          <Box minHeight={20} />
        </Box>
      </Box>
      <Box display="flex" gap={3} flex={1}>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <Grid container direction={{ md: "row" }}>
              <Grid item md={4}>
                <Typography gutterBottom variant="h6">{t(`question_editor.remove_variant`)}</Typography>
              </Grid>
              <Grid item md={8}>
                <Typography variant="subtitle1">{t(`question_editor.remove_language_variant_alert`)}</Typography>
                <Button sx={{ mt: 3 }} variant="outlined" color="error" onClick={removeVariant}>{t(`question_editor.remove_variant`)}</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

QuestionForm.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  questionType: PropTypes.object
}

export default QuestionForm