import { useState, useCallback } from 'react';
import { Button, Box, Select, MenuItem, IconButton, Typography } from '@mui/material';
import { Add, AddCircleOutline } from '@mui/icons-material';
import SelectsManager from './Dropdown/SelectsManager';
import useSelectManager from './Dropdown/useSelectManager.form';
import SelectDialog from './Dropdown/SelectDialog';
import { useTranslation } from 'react-i18next';

function DropdownEditor({ name, control, watch, errors }) {

  const { selects, addSelect, orderSelect, updateSelect, removeSelect, removeOption } = useSelectManager({ name, control });

  const question = watch(`${name}.assignment`);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const { t, i18n } = useTranslation()

  const lastIndex = selects?.length || 0;

  const renderPreview = useCallback((handleOpenDialog) => {
    if (!question)
      return <Typography p={2} variant='body1' textAlign="center" color="GrayText">{t(`question_editor.here_will_be_preview`)}</Typography>;

    const parts = question.split(/\$/g);

    return <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
      {parts.map((part, idx) => {
        const select = selects?.[idx]?.options?.find(i => i.correct);
        const selectId = select?.new_id || select?.id || 0;
        return <Box key={idx} display="flex" alignItems="center" gap={1}>
          {part}
          {parts[idx + 1] != undefined && !selects[idx] && <IconButton onClick={() => handleOpenDialog(idx)}><AddCircleOutline color="success" /></IconButton>}
          {selects[idx] && (
            <Select placeholder={t(`general.choose`)} value={selectId} onChange={(e) => handleCorrectChange(idx, e.target.value)}>
              <MenuItem disabled value={0}>{t(`general.choose`)}</MenuItem>
              {selects[idx]?.options?.map((option, optionIdx) => {
                return (
                  <MenuItem key={optionIdx} value={option.new_id || option.id} selected={Boolean(option?.correct)}>
                    {option.text}
                  </MenuItem>
                )
              })}
            </Select>
          )}
        </Box>
      }
      )}
    </Box>
  }, [question, selects]);

  const handleOpenDialog = useCallback((index = null) => {
    console.log("handleOpenDialog", index);
    setEditingIndex(index);
    setDialogOpen(true);
  }, []);

  const handleCorrectChange = (selectIndex, valueId) => {
    const options = selects[selectIndex].options;
    const newOptions = options.map((option) => ({
      ...option,
      correct: (option.new_id || option.id) === valueId
    }));
    updateSelect(selectIndex, newOptions);
    //setOptions(newOptions);
  };

  const handleSaveSelect = useCallback((options) => {
    if (editingIndex !== null) {
      console.log("handleSaveSelect - updateSelect", { editingIndex, options })
      updateSelect(editingIndex, options);
    } else {
      console.log("handleSaveSelect - addSelect", { lastIndex, options })
      addSelect(lastIndex, options);
    }
    setDialogOpen(false);
    setEditingIndex(null);
  }, [addSelect, updateSelect, editingIndex, lastIndex]);

  return (
    <Box>
      <Box mt={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{t(`question_editor.list_of_selections`)}</Typography>
          <Box>
            <Button
              variant="outlined"
              color="success"
              startIcon={<Add />}
              onClick={() => handleOpenDialog(lastIndex)}
            >
              {t(`question_editor.add_selection`)}
            </Button>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          {errors?.answers?.message && <Typography variant="h6" color="error.main">{errors.answers.message}</Typography>}
          <SelectsManager selects={selects} orderSelect={orderSelect} openDialog={handleOpenDialog} removeSelect={removeSelect} handleCorrectChange={handleCorrectChange} />
          {dialogOpen &&
            <SelectDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onSave={handleSaveSelect}
              initialOptions={editingIndex !== null ? selects[editingIndex]?.options : []}
              removeOption={removeOption}
              currentIndex={editingIndex || lastIndex}
            />}
        </Box>
      </Box>
      <Box mt={4}>
        <h3>{t(`question_editor.question_preview`)}:</h3>
        {renderPreview(handleOpenDialog)}
      </Box>
    </Box>
  );
}

export default DropdownEditor;
