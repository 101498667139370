import PropTypes from "prop-types"
import { Backdrop, Box, CircularProgress, Fade } from '@mui/material'

const Loader = ({ loading, delay = true }) => {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: (loading && delay) ? "300ms" : "0ms"
      }}
      unmountOnExit
    >
      <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Box sx={{ display: "flex" }}>
          <CircularProgress color="inherit" />
        </Box>
      </Backdrop>

    </Fade>
  )
}

Loader.propTypes = {
  loading: PropTypes.bool,
  delay: PropTypes.bool
}

export default Loader