import { useContext } from "react";
import { QuestionEditorContext } from "../contexts/QuestionEditorContext";

// Hook poskytující kontext
export function useQuestionEditorContext() {
  const context = useContext(QuestionEditorContext);
  if (!context) {
    throw new Error('useQuestionEditorContext must be used within a QuestionEditorProvider');
  }
  return context;
}