import { Box, Container, Typography, Unstable_Grid2 as Grid, Button, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import REST from '../utils/rest';
import { AccountPasswordEditing } from '../sections/account/account-password-editing';
import { ArrowLeftIcon } from '@mui/x-date-pickers';
import ErrorPage from './error_page'
import { toastError, toastSuccess } from '../utils/toasts';

const Page = () => {

    let { hash } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation()

    const passProps = { t, navigate, }

    const [user, setUser] = useState(null);

    const getData = useCallback(async () => {
        const [success, result] = await REST.GET(`change_password?hash=${encodeURIComponent(hash)}`);
        console.log(`[passwordchange]`, success, result)
        
        // return setUser({ PASSWORD_CHANGE_HASH: hash });

        if (success) {
            console.log('success ok ', result)
            //Nastaavíme jazyk
            i18n.changeLanguage(result.USER_ISO);
            setUser(result || false);

        }
        else
            setUser(false)
    }, [])

    useEffect(() => {
        console.log(`[passwordchange] getdata`)
        getData();
    }, [])

    const passwordSubmit = useCallback(async (values) => {
        values.hash = user?.PASSWORD_CHANGE_HASH;
        const [success, result] = await REST.POST(`change_password`, values)

        if (!success) {
            return toastError(t(result?.message))
        }

        toastSuccess(t(`account.password_changed`))

        navigate('/login')
        // return true

        return success;
    }, [user])

    console.log(`[passwordchange]`, user)

    if (user === false)
        return <ErrorPage img={"/assets/errors/error-404.png"} title={t(`errors.link_validity_expired`)} />

    if (!user)
        return <ErrorPage img={"/assets/errors/error-404.png"} title={t(`errors.link_validity_expired`)} />

    return <>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="xs">
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        xs={12}
                    >
                        <AccountPasswordEditing {...passProps} onSubmit={passwordSubmit} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </>
}

Page.getLayout = (page) => page

export default Page;
