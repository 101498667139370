import { Box, Button, Dialog, Card, CardActions, DialogContent, CardContent, DialogTitle, CardHeader, Container, Divider, Unstable_Grid2 as Grid, LinearProgress, SvgIcon, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '@/layouts/dashboard/layout';
import REST from '../utils/rest';
import { useCallback, useEffect, useState } from 'react';
import { toastError, toastSuccess } from '../utils/toasts';
import { useNavigate } from 'react-router-dom';
import DateRangePickerEmbeded from '../components/date-range-picker';
import { useTranslation } from 'react-i18next';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import SimpleBar from 'simplebar-react';
import { useWidth } from '../hooks/useWidth';
import TabLayout from '../components/TabLayout';
import { dateCZ, datetimeCZ } from '../utils/date-format-cz';
import { set } from 'date-fns';

const DateRange = ({ callback }) => {
  return <DateRangePickerEmbeded callback={callback} />
}

const TestsTable = ({ title, tests, tests_groups, tests_users, navigate, t, setDetailID }) => {

  const width = useWidth()

  const data = tests || tests_groups || tests_users || []

  return <Grid xs={12}>
    <Card sx={{ height: '100%' }}>
      <CardHeader title={title} />
      <SimpleBar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t(`general.test`)}
                </TableCell>
                {tests && <>
                  <TableCell>
                    {t(`menu.users`)}
                  </TableCell>
                  <TableCell>
                    {t(`menu.users`)} %
                  </TableCell>
                </>}
                {tests_groups && <TableCell>
                  {t(`test_evaluation.group`)}
                </TableCell>}
                {tests_users && <TableCell>
                  {t(`test_evaluation.user`)}
                </TableCell>}
                <TableCell>
                  {t(`test_evaluation.successful_attempts`)}
                </TableCell>
                <TableCell>
                  {t(`test_evaluation.unsuccessful_attempts`)}
                </TableCell>
                <TableCell>
                  {t(`test_evaluation.best_result`)}
                </TableCell>
                <TableCell>
                  {t(`test_evaluation.average_result`)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((rec, index) =>
                <TableRow
                  hover
                  key={index}
                  // style={{ cursor: `pointer` }}
                  onClick={() => setDetailID(rec.TEST_KIT_ID)}
                >
                  <TableCell>
                    {rec.TEST_KIT_NAME}
                  </TableCell>
                  {tests && <>
                    <TableCell>
                      {rec.USER_COUNT}
                    </TableCell>
                    <TableCell>
                      {rec.USER_PERCENT}%
                    </TableCell>
                  </>}
                  {tests_groups && <TableCell>
                    {rec.USER_GROUP_NAME}
                  </TableCell>}
                  {tests_users && <TableCell>
                    {rec.USER_NAME} {rec.USER_SURNAME}
                  </TableCell>}
                  <TableCell>
                    {rec.ATTEMPTS_SUCCESSFUL}
                  </TableCell>
                  <TableCell>
                    {rec.ATTEMPTS_UNSUCCESSFUL}
                  </TableCell>
                  <TableCell>
                    {rec.ATTEMPT_BEST}%
                    <LinearProgress
                      title={`${rec.SUC_PERCENTAGE_BEST}%`}
                      value={rec.ATTEMPT_BEST}
                      variant="determinate"
                      thickness={4}
                    />
                  </TableCell>
                  <TableCell>
                    {rec.ATTEMPT_AVG}%
                    <LinearProgress
                      title={`${rec.SUC_PERCENTAGE_BEST}%`}
                      value={rec.ATTEMPT_AVG}
                      variant="determinate"
                      thickness={4}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {data?.length === 0 && <Typography align='center' style={{ width: `100%`, padding: 32 }} >{t(`general.no_records`)}</Typography>}
        </Box>
      </SimpleBar>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
      </CardActions>
    </Card>
  </Grid>
}

const Detail = ({ dateRange, setDetailID, id, t, navigate }) => {

  const [data, setData] = useState(null)

  const getData = useCallback(async () => {
    if (dateRange?.[0] && dateRange?.[1] && id) {
      const params = { from: new Date(dateRange?.[0]).getTime(), to: new Date(dateRange?.[1]).getTime(), test_kit_id: id }
      const queryParams = `?${Object.keys(params)?.map(key => `${key}=${params[key]}`).join(`&`)}`
      console.log({ params, queryParams })
      const [success, newdata] = await REST.GET(`test_kit_history${queryParams}`)
      if (!success) toastError(`${t(`general.error`)} ${t(`general.loading_data`)}`)
      setData(newdata)
    }

  }, [dateRange, id])

  useEffect(() => {
    if (id && dateRange?.[0] && dateRange?.[1])
      getData()
  }, [dateRange, id])

  const close = () => {
    setData(null)
    setDetailID(null)
  }

  if (!data) return null;

  console.log({ data })

  return <Dialog open={true} onClose={close}>
    <DialogTitle>{t(`TEST_HISTORY`)}</DialogTitle>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            {t(`general.test`)}
          </TableCell>
          <TableCell>
            {t(`test_evaluation.user`)}
          </TableCell>
          <TableCell>
            {t(`general.date`)}
          </TableCell>
          <TableCell>
            {t(`general.state`)}
          </TableCell>
          <TableCell>
            {t(`general.result`)}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((test, index) =>
          <TableRow
            hover
            key={index}
            style={{ cursor: `pointer` }}
            onClick={() => navigate(`/test/${test?.TEST_HISTORY_ID}`)}
          >
            <TableCell>
              {test.TEST_KIT_NAME}
            </TableCell>
            <TableCell>
              {test.USER_NAME} {test.USER_SURNAME}
            </TableCell>
            <TableCell>
              {datetimeCZ(test?.ATTEMPT_DATE)}
            </TableCell>
            <TableCell>
              {test?.TEST_HISTORY_STATE_NAME}
            </TableCell>
            <TableCell>
              {test.SUC_PERCENTAGE}%
              <LinearProgress
                title={`${test.SUC_PERCENTAGE}%`}
                value={test.SUC_PERCENTAGE}
                variant="determinate"
                thickness={4}
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Dialog>
}

const Page = () => {

  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([null, null])
  const [data, setData] = useState(null);
  const { t, i18n } = useTranslation()
  const [tab, setTab] = useState(0);
  const [detailID, setDetailID] = useState(null)

  const getData = useCallback(async (export_data) => {
    console.log(`getData`, dateRange, dateRange?.[0] && dateRange?.[1])
    if (dateRange?.[0] && dateRange?.[1]) {
      const params = { from: new Date(dateRange?.[0]).getTime(), to: new Date(dateRange?.[1]).getTime() }
      const queryParams = `?${Object.keys(params)?.map(key => `${key}=${params[key]}`).join(`&`)}`
      console.log({ params, queryParams })
      const [success, newdata] = await REST.GET(`test_evaluation${queryParams}${export_data ? `&export=1` : ``}`, { download: export_data })
      if (!success) toastError(`${t(`general.error`)} ${t(`general.loading_data`)}`)
      if (export_data) return toastSuccess(`${t(`test_evaluation.export`)} ${t(`test_evaluation.successful`)}`)
      setData(newdata)
    }
  }, [dateRange])

  // useEffect(() => {
  //   console.log({ width })
  // }, [width])

  useEffect(() => {
    console.log(`date range changed`, dateRange)
    getData()
  }, [dateRange])

  // if (!data)
  //   return <Skeleton />

  return <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 4
    }}
  >
    <Container>

      {/* TITLE */}
      <Box display={`flex`} justifyContent={`space-between`} marginBottom={2} flexWrap={"wrap"}>
        <Typography variant="h2" marginBottom={0}>{t(`test_evaluation.title`)}</Typography>
        <Button
          sx={{ fontSize: 32 }}
          onClick={() => getData(true)}
          startIcon={(
            <SvgIcon sx={{ fontSize: 32 }}>
              <ArrowUpTrayIcon sx={{ fontSize: 32 }} />
            </SvgIcon>
          )}>
          {t(`test_evaluation.export`)}
        </Button>
      </Box>

      <Grid container spacing={3} >
        <Grid>
          <DateRange callback={setDateRange} />
        </Grid>
      </Grid>

      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} aria-label="basic tabs example">
          <Tab label="Item One" />
          <Tab label="Item Two" />
          <Tab label="Item Three" />
        </Tabs>
      </Box> */}

      <TabLayout
        tabs={[
          {
            name: `${t(`test_evaluation.tests_succes_rate`)} ${t(`test_evaluation.generaly`)}`,
            component: <TestsTable title={`${t(`test_evaluation.tests_succes_rate`)} ${t(`test_evaluation.generaly`)}`} tests={data?.tests} navigate={navigate} {...({ t, i18n, setDetailID })} />
          },
          {
            name: `${t(`test_evaluation.tests_succes_rate`)} ${t(`test_evaluation.by_user_groups`)}`,
            component: <TestsTable title={`${t(`test_evaluation.tests_succes_rate`)} ${t(`test_evaluation.by_user_groups`)}`} tests_groups={data?.tests_groups} navigate={navigate} {...({ t, i18n, setDetailID })} />
          },
          {
            name: `${t(`test_evaluation.tests_succes_rate`)} ${t(`test_evaluation.by_users`)}`,
            component: <TestsTable title={`${t(`test_evaluation.tests_succes_rate`)} ${t(`test_evaluation.by_users`)}`} tests_users={data?.tests_users} navigate={navigate} {...({ t, i18n, setDetailID })} />
          },
        ]}
      />

      {detailID && <Detail id={detailID} data={data} {...({ t, i18n, navigate, dateRange, setDetailID })} />}

      {/* <Grid container spacing={3} role="tabpanel" hidden={tab !== 0}>
        <TestsTable title={`${t(`test_evaluation.tests_succes_rate`)} ${t(`test_evaluation.generaly`)}`} tests={data?.tests} navigate={navigate} {...({ t, i18n })} />
      </Grid>

      <Grid container spacing={3} role="tabpanel" hidden={tab !== 1}>
        <TestsTable title={`${t(`test_evaluation.tests_succes_rate`)} ${t(`test_evaluation.by_user_groups`)}`} tests_groups={data?.tests_groups} navigate={navigate} {...({ t, i18n })} />
      </Grid>

      <Grid container spacing={3} role="tabpanel" hidden={tab !== 2}>
        <TestsTable title={`${t(`test_evaluation.tests_succes_rate`)} ${t(`test_evaluation.by_users`)}`} tests_users={data?.tests_users} navigate={navigate} {...({ t, i18n })} />
      </Grid> */}


    </Container >
    {/* <Placeholder /> */}
  </Box >
}

Page.getLayout = (page) => (
  <DashboardLayout>
    {page}
  </DashboardLayout>
);

export default Page;
