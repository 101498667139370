import apiClient from './apiClient';

/**
 * Vlastní chybová třída pro lepší zacházení s chybami.
 */
class ApiServiceError extends Error {
  constructor(message, data) {
    super(message);
    this.data = data;
  }
}

// Interceptors lze využít k odesílání dat do backendu

// Interceptor pro logování požadavků
apiClient.interceptors.request.use(request => {
  //console.log('Starting Request:', request);
  return request;
});

// Interceptor pro logování odpovědí
apiClient.interceptors.response.use(response => {
  //console.log('Response:', response);
  return response;
}, error => {
  //console.error('Error Response:', error);
  return Promise.reject(error);
});

/**
 * Získání detailu otázky.
 * 
 * @param {number} questionId - ID otázky.
 * @returns {Promise<Object>} - Detail otázky.
 * @throws {ApiServiceError} - Pokud dojde k chybě při komunikaci s API.
 */
export const getQuestionById = async (questionId) => {
  try {
    const response = await apiClient.get(`/question_edit/${questionId}`);
    return response.data;
  } catch (error) {
    throw new ApiServiceError('Error fetching question detail', error.response?.data);
  }
};

/**
 * Aktualizace otázky.
 * 
 * @param {number} questionId - ID otázky.
 * @param {Object} updatedData - Aktualizovaná data otázky.
 * @returns {Promise<Object>} - Aktualizovaná otázka.
 * @throws {ApiServiceError} - Pokud dojde k chybě při komunikaci s API.
 */
export const updateQuestion = async (questionId, updatedData) => {
  try {
    const response = await apiClient.put(`/question_edit/${questionId}`, updatedData);
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.Message;
    throw new ApiServiceError(message || 'Error updating question', error.response?.data);
  }
};