import PropTypes from "prop-types"
import { createContext, useContext, useState } from "react";

const TestContext = createContext();

export const useTestContext = () => useContext(TestContext);

const TestProvider = ({ children }) => {
  // Načtená data
  const [loading, setLoading] = useState(true);
  const [test, setTest] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [progress, setProgress] = useState();
  const [answer, setAnswer] = useState();
  const [error, setError] = useState();

  const isAnswered = progress?.phase === "evaluation";
  const isSuccess = isAnswered ? currentQuestion.success : undefined;
  const lives = test?.totalLives - progress?.wrongAnswers;
  const hasAnswer = Array.isArray(answer) ? answer.length > 0 : !!answer;

  const context = {
    // states
    test, setTest,
    progress, setProgress,
    loading, setLoading,
    currentQuestion, setCurrentQuestion,
    answer, setAnswer,
    error, setError,
    // calculated
    isAnswered,
    isSuccess,
    lives,
    hasAnswer
  };

  return <TestContext.Provider value={context}>
    {children}
  </TestContext.Provider>
}

TestProvider.propTypes = {
  children: PropTypes.node
}

export default TestProvider;
