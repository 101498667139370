export const tabs = [
  { id: "cs", label: "Česky", used: true },
  { id: "en", label: "Anglicky", used: true }
]

export const question = {
  id: 1,
  name: "Název otázky 🙂",
  type: "closed",
  multiple: true,
  settings: {
    questionMaxLength: 150,
    answerMaxLength: 80,
    limitAnswers: 10
  },
  locales: {
    cs: { // Česky
      question: "Co znamená v HTML dokumentu <!DOCTYPE html> a ovlivňuje chování prohlížeče ?",
      image: "/assets/img/otazkaZelenyChodnik.png",
      answers: [
        { id: 1, text: "Definice starého HTML dokumentu", correct: false },
        { id: 2, text: "Definice HTML 5 dokumentu", correct: true },
        { id: 3, text: "Ano, ovlivňuje chování prohlížeče", correct: true },
        { id: 4, text: "Ne, je to pozůstatek starého HTML", correct: false }
      ],
      additional: {
        keyPoint: "V nové verzi HTML 5 se začal dokument označovat <!DOCTYPE html> přičemž nezáleží na velikosti písmen.",
        fact: "Věděli jste že <!DOCTYPE html> říká prohlížeči jak má dokument zobrazit ?"
      },
      en: { // Anglicky
        // ....
      }
    }
  }
};

export const questionTypes = {
  closed: {
    "id": "closed",
    "label": "Uzavřená",
    "description": "question_editor.type_description.closed",
    "options": {
      questionImage: true,
      "imageAnswer": false,
      "multiAnswer": true
    }
  },
  closedimg: {
    "id": "closedimg",
    "label": "Uzavřená s obrázkem",
    "description": "question_editor.type_description.closedimg",
    "options": {
      "imageAnswer": true,
      "multiAnswer": true
    }
  },
  order: {
    "id": "order",
    "label": "Řazení",
    "description": "question_editor.type_description.order",
    options: {
      questionImage: true
    }
  },
  range: {
    "id": "range",
    "label": "Posuvník",
    "description": "question_editor.type_description.range",
    options: {
      questionImage: true
    }
  },
  dropdown: {
    "id": "dropdown",
    "label": "Doplňování",
    "description": "question_editor.type_description.dropdown",
    "input.assignment.helperText": "question_editor.use_$_for_selection",
    options: {
      questionImage: true
    }
  }
}