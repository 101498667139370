import { useFieldArray } from "react-hook-form";

function useSelectManager({ name, control }) {
  const { fields: selects, append, update, remove } = useFieldArray({
    control,
    name: `${name}.answers`,
    keyName: "rhf_id"
  });
  const { fields: removedSelects, append: appendRemoved } = useFieldArray({
    control,
    name: `${name}.removed_answers`,
    keyName: "rhf_id"
  });

  const addSelect = (index, select) => {
    append({
      options: select.map(i => ({ ...i, field: index }))
    });
  };

  const updateSelect = (index, updatedSelect) => {
    update(index, { options: updatedSelect.map(i => ({ ...i, field: index })) });
  };

  const removeOption = (option) => {
    if (option.id) {
      appendRemoved(option);
    }

  }

  const updateAllFields = (selects) => {
    selects.map((i, index) => updateSelect(index, i.options));
  }

  const removeSelect = (index) => {
    const select = selects[index];
    select.options.map(i => appendRemoved(i));
    remove(index);
    // Aktualizuji všechny fields
    selects.filter((i, filtrSelIdx) => filtrSelIdx != index).map((i, selIdx) => {
      update(index, { options: i.options.map(i => ({ ...i, field: selIdx })) });
    })
  };

  const orderSelect = (currentIndex, newIndex) => {
  };

  return {
    selects,
    addSelect,
    updateSelect,
    removeSelect,
    orderSelect,
    removeOption
  };
}

export default useSelectManager;