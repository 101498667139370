import { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Unstable_Grid2 as Grid
} from '@mui/material';
import flags from '../../data/languages.json'

import PropTypes from 'prop-types';
import REST from '../../utils/rest';

export const AccountPasswordEditing = ({ t, user, login, logout, navigate, changeLanguage, handleSignOut, languages, onSubmit }) => {

    const [values, setValues] = useState({
        oldPassword: "",
        newPassword: "",
        newPasswordAgain: "",
    });

    const [blockSubmit, setBlockSubmit] = useState(false)

    const handleChange = useCallback(
        (event) => {
            setValues((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }));
        },
        []
    );

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();
            setBlockSubmit(true)
            const result = await onSubmit?.(values)
            setBlockSubmit(false)
            if (result) {
                setValues({
                    oldPassword: "",
                    newPassword: "",
                    newPasswordAgain: "",
                })

            }
        }, [values]
    );

    return (
        <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
        >
            <Card>
                <CardHeader
                    title={t(`account.change_password`)}
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            {/* <Grid
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label={t(`account.old_password`)}
                                    name="oldPassword"
                                    onChange={handleChange}
                                    required
                                    value={values.oldPassword}
                                />
                            </Grid> */}
                            <Grid
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label={t(`account.new_password`)}
                                    name="newPassword"
                                    onChange={handleChange}
                                    required
                                    type='password'
                                    value={values.newPassword}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label={t(`account.repeat_new_password`)}
                                    name="newPasswordAgain"
                                    onChange={handleChange}
                                    required
                                    type='password'
                                    value={values.newPasswordAgain}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button disabled={blockSubmit} type='submit' variant="contained">{t(`general.save`)}</Button>
                </CardActions>
            </Card>
        </form >
    );
};

//props validation
AccountPasswordEditing.propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired,
    handleSignOut: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired,
}
