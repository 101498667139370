/**
 * Kontext pro globální stav zobrazení fullscreen medií
 */
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { broadcastEvent } from '../utils/events';
import useEventListener from '../hooks/useEventListener';

export const FileDropContext = createContext();

export function useFileDrop(path, callback) {
  const context = useContext(FileDropContext);

  useEventListener(`FILE_DROP_RESULT`, ({ detail }) => callback && callback(detail))

  // useEffect(() => {
  //   console.log(`FileDropContext`, { path, context })
  //   context.setPath(path);
  // }, [path, context]);

  return context;
}

export function FileDropProvider({ children }) {
  const [data, setData] = useState(null)
  // const [path, setPath] = useState(null)

  const open = (props = {}) => {
    console.log(`[file drop] open`, data, props)
    props.open = true;
    setData(props)
  }

  const close = (result) => {
    if (result)
      broadcastEvent('FILE_DROP_RESULT', result)
    setData(null)
    // setPath(null)
  }

  const contextValue = {
    isOpen: data?.open || false,
    data,
    path: data?.path || null,
    open,
    close,
    // setPath
  };

  return (
    <FileDropContext.Provider value={contextValue}>
      {children}
    </FileDropContext.Provider>
  );
}

FileDropProvider.propTypes = {
  children: PropTypes.element
}
