import PropTypes from "prop-types"
import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, List, ListItem, ListItemButton, Typography } from '@mui/material'
import TabList from "../Tabs/Tabs"
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const dialog = {
  id: "add-language-variant",
  heading: t("ADD_LANGUAGE"),
  text: "Vyberte jazyk který chcete přidat",
};

const AddLanguageDialog = ({ isOpen, onSelect, onClose, languages, emptyMessage }) => {

  const { t, i18n } = useTranslation()

  const handleClose = () => {
    onClose();
  }

  const handleSelect = (languageId) => {
    onSelect(languageId);
  }

  return (
    <Dialog open={isOpen} id={dialog.id} onClose={handleClose}>
      <DialogTitle> {dialog.heading} </DialogTitle>
      <List sx={{ pt: 0 }}>
        {languages.length === 0 && emptyMessage && <Typography p={3} color="GrayText">{emptyMessage}</Typography>}
        {languages.map(lang => (
          <ListItem disableGutters key={lang.id}>
            <ListItemButton onClick={() => handleSelect(lang.id)}>
              {lang.name}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button onClick={handleClose}>{t(`general.close`)}</Button>
      </DialogActions>
    </Dialog>
  )
}

const LanguageTabs = ({ tabs = [], currentTab = 0, setTab, languages, addVariant, activeVariants, dialogOpen, setDialogOpen }) => {

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  if (!languages) return null;

  const availableLanguages = languages?.filter(i => !activeVariants?.includes(i.id)) || [];

  return <Box pt={2} px={2}>
    <TabList
      items={tabs}
      value={currentTab}
      addTab={openDialog}
      onChange={(_, newValue) => setTab(newValue)}
    />

    <AddLanguageDialog
      isOpen={dialogOpen}
      languages={availableLanguages}
      onClose={closeDialog}
      onSelect={(langId) => {
        addVariant(langId);
        closeDialog();
      }}
      emptyMessage={t("question_editor.no_other_lang")}
    />
    <Divider />
  </Box>
}


AddLanguageDialog.propTypes = {
  isOpen: PropTypes.bool,
  languages: PropTypes.array,
  addLanguage: PropTypes.func,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  emptyMessage: PropTypes.string
}

LanguageTabs.propTypes = {
  activeVariants: PropTypes.array,
  addVariant: PropTypes.func,
  currentTab: PropTypes.number,
  dialogOpen: PropTypes.bool,
  languages: PropTypes.array,
  setDialogOpen: PropTypes.func,
  setTab: PropTypes.func,
  tabs: PropTypes.array
}

export default LanguageTabs