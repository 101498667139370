import * as yup from 'yup';

export const setYupLocale = (i18next) => {
  yup.setLocale({
    mixed: {
      default: i18next.t("editor_warnings.mixed.default"),
      required: i18next.t("editor_warnings.mixed.required"),
      oneOf: i18next.t("editor_warnings.mixed.oneOf"),
      notOneOf: i18next.t("editor_warnings.mixed.notOneOf"),
      notType: i18next.t("editor_warnings.mixed.notType")
    },
    string: {
      length: i18next.t("editor_warnings.string.length"),
      min: i18next.t("editor_warnings.string.min"),
      max: i18next.t("editor_warnings.string.max"),
      email: i18next.t("editor_warnings.string.email"),
      url: i18next.t("editor_warnings.string.url"),
      trim: i18next.t("editor_warnings.string.trim"),
      lowercase: i18next.t("editor_warnings.string.lowercase"),
      uppercase: i18next.t("editor_warnings.string.uppercase")
    },
    number: {
      min: i18next.t("editor_warnings.number.min"),
      max: i18next.t("editor_warnings.number.max"),
      lessThan: i18next.t("editor_warnings.number.lessThan"),
      moreThan: i18next.t("editor_warnings.number.moreThan"),
      notEqual: i18next.t("editor_warnings.number.notEqual"),
      positive: i18next.t("editor_warnings.number.positive"),
      negative: i18next.t("editor_warnings.number.negative"),
      integer: i18next.t("editor_warnings.number.integer")
    },
    date: {
      min: i18next.t("editor_warnings.date.min"),
      max: i18next.t("editor_warnings.date.max")
    },
    array: {
      min: i18next.t("editor_warnings.array.min"),
      max: i18next.t("editor_warnings.array.max")
    }
  });
}

export const getQuestionSchema = (i18next) => {
  const categorySchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    chosen: yup.boolean()
  });

  const fileSchema = yup.object().shape({
    name: yup.string(),
    type: yup.string(),
    mime: yup.string().nullable(),
    url: yup.string().required()
  }).nullable();

  const answerSchema = yup.object().shape({
    id: yup.number(),
    correct: yup.number().required(),
    text: yup.string().required(),
    field: yup.number().nullable(),
    files: yup.array().of(fileSchema).nullable()
  });

  const answerOrderSchema = yup.object().shape({
    id: yup.number().default(-1),
    correct: yup.number().required()
  })

  const variantRangeSchema = yup.object().shape({
    id: yup.number(),
    language: yup.number().required(),
    assignment: yup.string().required(),
    additional: yup.object().shape({
      keyPoint: yup.string().nullable(),
      fact: yup.string().nullable()
    }),
    rangeMin: yup.number().required(),
    rangeMax: yup.number().required(),
    files: yup.array().of(fileSchema).nullable(),
    answers: yup.array().of(yup.object().shape({
      id: yup.number().default(-1),
      correct: yup.number().required()
        .test(`range-value-min`, i18next.t("question_editor.correct_no_between_min_max"), (value, ctx) => {
          const { rangeMin, rangeMax } = ctx.from[1].value;
          return value >= Number(rangeMin) && value <= Number(rangeMax);
        })

    }))
  });

  const variantDropDownSchema = yup.object().shape({
    id: yup.number(),
    language: yup.number().required(),
    assignment: yup.string().required(),
    additional: yup.object().shape({
      keyPoint: yup.string().nullable(),
      fact: yup.string().nullable()
    }),
    answers: yup.array()
      .test(`match-dollar-signs`, i18next.t("question_editor.invalid_num_dropdowns"), (value, ctx) => {
        const matchDollarSigns = ctx.parent.assignment.match(/\$/g)?.length;
        if (!matchDollarSigns) return true;
        return matchDollarSigns && (matchDollarSigns <= value?.length);
      })
      .test(`every-select-need-default-option`, i18next.t("question_editor.every_drpdwn_needs_default"), (value) => {
        return value.map(select => select.options.find(i => i.correct)).filter(Boolean)?.length === value?.length;
      })
  });

  const variantSchema = yup.object().shape({
    id: yup.number(),
    language: yup.number().required(),
    assignment: yup.string().required(),
    additional: yup.object().shape({
      keyPoint: yup.string().nullable(),
      fact: yup.string().nullable()
    }),
    files: yup.array().of(fileSchema).nullable(),
    answers: yup.array().when('$question.type', {
      is: "range",
      then: (schema) => schema.of(answerOrderSchema),
      otherwise: (schema) => schema.of(answerSchema),
    }),
  });

  const questionSchema = yup.object().shape({
    question: yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
      type: yup.string().oneOf(['closed', 'closedimg', 'order', 'range', 'dropdown']).required(),
      categories: yup.array().of(categorySchema).min(1)
    }),
    variants: yup.array()
      .when("question.type", ([type], schema) => {
        switch (type) {
          case "range":
            return schema.of(variantRangeSchema).min(1);
          case "dropdown":
            return schema.of(variantDropDownSchema).min(1);
          default:
            return schema.of(variantSchema).min(1);
        }
      })
  });

  return questionSchema;
}
