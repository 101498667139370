import { t } from "i18next";
import { broadcastEvent } from "./events";
import { storageGet, storageSet } from "./local-storage";
import { toastError } from "./toasts";

function tryCatch(fn, errMsg) {
    try {
        return fn();
    } catch (err) {
        errMsg && console.error("tryCatch error msg:", errMsg, "error:", err);
        return false;
    }
}

/**
 * Centrální funkce pro zjednodušení používání fetch funkce
 * @param method Metoda fetch
 * @param param Adresa fetch následující po /ajax/
 * @param body - Body v JSON pro fetch
 * @param cb - DEPRECATED - callback ( v případě selhání je response "failed")
 * @param loader bool - pro zobrazení loader
 * @param contentType str - možnosti 'application/json' a 'multipart/form-data', default 'application/json'
 * @param download bool - true pokud se jedná o soubor ke stažení
 * @param success fn(response) - callback v případě úspěšného fetch
 * @param error fn(errorCode) - callback v případě neúspěšného fetch
 */
export function centralFetch(props) {

    let {
        method = "GET",
        param,
        body = null,
        cb = () => { },
        loader = true,
        contentType = "application/json",
        download = false,
        success = () => { },
        error = () => { },
        debug = false,
        // loaderDelay = 300,
        bypasAuth = false,
    } = props;

    //console.log(`centralFetch`, props)

    //console.log("loader", loader);
    if (debug) {
        success(debug);
        return cb(debug);
    }

    //let timer;
    //if (loader)
    //    timer = setTimeout(() => toggleLoader(true), loaderDelay);

    let headers = {};

    // Funkce pro zpracování body pro fetch
    function checkBody(body) {
        if (contentType === "application/json") {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Headers": "X-Server-Started, ETag, Baggage, Sentry-Trace",
                "Access-Control-Expose-Header": "X-Server-Started, ETag, Baggage, Sentry-Trace",
            };
            if (body) return JSON.stringify(body);
        } else if (contentType === "multipart/form-data") {
            // headers = {
            //     Accept: "multipart/form-data",
            //     "Content-Type": "multipart/form-data",
            //     "Access-Control-Allow-Headers": "X-Server-Started, ETag",
            //     "Access-Control-Expose-Header": "X-Server-Started, ETag",
            // };
            // let data = new FormData();
            // Object.keys(body).map((item) => data.append(item, body[item]));
            return {};
        }
    }

    // Funkce pro kontrolu, zda je třeba aplikaci restartovat
    function checkResponse(response) {
        const meta = storageGet("meta");
        const serverStarted = response?.headers?.get("X-Server-Started");
        const serverVersion = response?.headers?.get("X-Server-Version");

        // Vytvořím nové meta podle dat z response a starého meta
        const newMeta = {
            ...meta,
            serverVersion: serverVersion,
            serverStarted: serverStarted,
        };

        // Pokud se serverStarted nerovná tak proběhl restart serveru a proto spustím reload stránky
        if (+meta?.serverStarted !== +serverStarted) {
            storageSet("meta", newMeta);

            // Zavolám callback aby se vše požadované uložilo
            const resjson = response?.json()
            if (cb) cb(resjson);
            success(resjson);

            // A vyvolám refresh
            return window.location.reload();
        }

        // Data nakonec uložím;
        storageSet("meta", newMeta);
    }

    param = param.replace('ajax/', '');

    if (loader)
        broadcastEvent('fetch_loader', true);

    window.centralFetchRunning = (window.centralFetchRunning || 0) + 1;

    //nastaneví request timeoutu
    const controller = new AbortController()
    let timespan = parseInt(import.meta.env?.REACT_APP_REQUEST_TIMEOUT) || null;
    if (method === `GET`)
        timespan = parseInt(import.meta.env?.REACT_APP_REQUEST_TIMEOUT_GET) || null;

    const fetchAbortTimeout = setTimeout(() => {
        if (!timespan) return;
        console.log(`central fetch... abort`, { timespan })
        controller.abort();
        // if (cb) cb("failed");
    }, timespan)

    const URL = import.meta.env.VITE_SERVER_URL
        ? import.meta.env.VITE_SERVER_URL + "ajax/"
        : "/ajax/";

    //console.log(`[fetch]`, props, method, URL, param, body, headers)

    fetch(URL + param, {
        body: checkBody(body),
        mode: "cors",
        cache: "no-cache",
        headers,
        method: method,
        credentials: "include",
        signal: controller.signal,
    })
        .then((response) => {

            clearTimeout(fetchAbortTimeout)

            //console.log(`[fetch] result`, method, URL, param, body, headers, response)

            if (response.status === 503 && cb)
                throw new Error()

            if (response.status === 401 && !bypasAuth) {
                toastError(t(`auth.user_logged_out`))
                window?.location?.reload()
            }

            checkResponse(response);

            if (download) {
                if (response.status !== 200) return response.text();
                return JSON.stringify({ Result: "OK", Code: 100, Results: response });
            }

            return response?.text();
        })
        .then((text) => {

            try {
                const response = tryCatch(() => JSON?.parse?.(text));
                // FIXME: Tento způsob stahování souborů je zdlouhavý, chce to jiné řešení - tento dotaz na soubor se provede 2x
                if (download) {
                    if (response.Code !== 100) return toastError(response.Message);
                    return (document.getElementById("customFrameTool").src = URL + param);
                }

                if (cb) cb(response);
                success(response);

            } catch (e) {
                if (cb) cb("failed");
                error(501);
            }
        })
        .catch((err) => {
            console.log(err)
            if (cb) cb("failed");
            error(502);
        }).finally(() => {
            if (loader)
                broadcastEvent('fetch_loader', false);
        });
}
