import REST from "../utils/rest";
import { toastError } from "../utils/toasts";

export async function openTest(TEST_KIT_ID) {
    const [success, result] = await REST.POST(`open_test`, { TEST_KIT_ID })
    console.log(`openTest`, { success, result })
    if (!success || !result) {
        toastError(`err`);
        return null;
    }
    return result;
}
