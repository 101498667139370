import PropTypes from 'prop-types'
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';

/**
 * Komponenta pro zobrazení počtu životů
 * @param {number} total Celkový počet životů
 * @param {number} remaining Zbývající počet životů 
 * @returns 
 */
const LiveIndicator = ({ total, remaining }) => {
  return (
    <Rating
      readOnly
      name="customized-color"
      value={remaining}
      max={total}
      getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
      icon={<FavoriteIcon sx={{ color: "#ff6d75" }} fontSize="inherit" />}
      emptyIcon={<FavoriteIcon fontSize="inherit" style={{ opacity: 0.55 }} />}
    />)
}

LiveIndicator.propTypes = {
  total: PropTypes.number, // Celkový počet životů
  remaining: PropTypes.number // Zbývající počet životů
}

export default LiveIndicator