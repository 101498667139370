import * as Sentry from '@sentry/react';
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from './components/protected-route';
import LogInPage from './pages/login';
import ChangePasswordPage from './pages/change_password';
import Layout from "./Layout";

import { CacheProvider } from '@emotion/react';
import { createEmotionCache } from '@/utils/create-emotion-cache';

import toast, { Toaster } from 'react-hot-toast';
import { error, neutral, success } from "./theme/colors";

import "./index.css"
import './assets/iconfont/style.scss';
import Fullscreen from "./components/media-view-fullscreen";
import FileManagerDialog from "./components/file-manager-dialog";
import FileDropDialog from "./components/file-drop-dialog";
import "./ReactTableLibrary.scss"
// import './styles/react-table-library.scss'
import './styles/table_override.scss'
import { Portal } from '@mui/material';
import RTLS from './rtls/rtls';

window.toast = toast;

const emotionCache = createEmotionCache();
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  // const auth = useAuth();

  return (
    <CacheProvider className="x" value={emotionCache}>
      <SentryRoutes className="x">
        <Route path="*" element={<ProtectedRoute component={<Layout />} />} />
        <Route path="/login" element={LogInPage.getLayout(<LogInPage />)} />
        <Route path="/change_password/:hash" element={ChangePasswordPage.getLayout(<ChangePasswordPage />)} />
        <Route path="/map" element={RTLS.getLayout(<RTLS />)} />
      </SentryRoutes>
      <Portal>
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          containerClassName="toaster"
          containerStyle={{}}
          toastOptions={{
            className: 'toast',
            duration: 3000,
            style: {
              background: neutral[50],
              color: neutral[900],
            },
            success: {
              // duration: 3000,
              theme: {
                primary: success.main,
                color: success.contrastText,
              },
            },
            error: {
              // duration: 300000000000,
              theme: {
                primary: error.main,
                color: error.contrastText,
              },
            },
          }}
        />
      </Portal>
      <Fullscreen />
      <FileManagerDialog />
      <FileDropDialog />
      <iframe
        id="customFrameTool"
        title="Download frame"
        style={{ display: 'none' }}
      />
    </CacheProvider>
  );
}

export default App;
