import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Typography, Button, IconButton, Card, CardMedia, Paper, Chip, Container, CardActions, CardContent, SvgIcon, Fab } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack.js';
import { useTranslation } from 'react-i18next';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import BookIcon from '@mui/icons-material/Book';
import ImageIcon from '@mui/icons-material/Image';
import DownloadIcon from '@mui/icons-material/Download';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoIcon from '@mui/icons-material/PlayCircleFilled';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import config from "../../../config.js";
import { useNavigate } from 'react-router-dom';
import MediaView from "../../../components/media-view.jsx"
import REST from '../../../utils/rest.js';

const { serverUrl } = config;

const StyledPlayButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  color: 'white',
  fontSize: '6rem',
  transition: 'transform 0.3s ease, color 0.3s ease',
  '&:hover': {
    color: theme.palette.primary.main,
    transform: "translate(-50%, -50%) scale(1.1)",
  },
}));

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 640px;
  height: 360px;
  overflow: hidden;
  background: black;
`;

const VideoPlayer = ({ url }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
    const videoElement = document.getElementById("videoElement");
    videoElement.play();
  };

  const { href } = new URL(url, serverUrl);

  return (
    <VideoContainer>
      {!isPlaying && (
        <StyledPlayButton onClick={handlePlay}>
          <PlayArrowRoundedIcon fontSize="inherit" />
        </StyledPlayButton>
      )}
      <video
        id="videoElement"
        width="100%"
        height="100%"
        controls={isPlaying}
        style={{ filter: isPlaying ? 'none' : 'brightness(0.5)' }}
      >
        <source src={href} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </VideoContainer>
  );
};

const determineIcon = (mime) => {
  if (mime.startsWith('video/')) return <VideoIcon sx={{ fontSize: 40 }} />;
  if (mime.startsWith('image/')) return <ImageIcon sx={{ fontSize: 40 }} />;
  if (mime === 'application/pdf') return <PdfIcon sx={{ fontSize: 40 }} />;
  return null;
};

const SmallMediaCard = ({ data }) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: "flex" }}>
      {data.map((item, index) => (
        <Card key={index} sx={{ minWidth: "10vw", maxWidth: 150, m: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CardContent>
            <Box>{determineIcon(item.mime)}</Box>
            <Typography variant="caption" align="center">{item.name}</Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" size="small" href={item.url} target="_blank" sx={{ mt: 1 }}>
              {t("question_evaluation.show")}
            </Button>
          </CardActions>
        </Card>
      ))
      }
    </Box >
  );
};


const TestIntro = ({ test, confirm, intro }) => {
  const { t } = useTranslation()
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  }

  return (
    <Container maxWidth="md" sx={{ minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      {intro && <Fab onClick={onBack} color="primary" sx={{ position: "absolute", left: "2rem", top: "2rem" }}>
        <ArrowBack />
      </Fab>}
      <Paper
        elevation={intro ? 5 : 0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          ...(intro ? {
            padding: 3,
            borderRadius: 3,
            backgroundColor: '#f5f5f5'
          } : {})
        }}
      >
        {intro && <>

          <Typography variant="h3" gutterBottom align="center" >
            {test.name}
          </Typography>

          <Box display="flex" justifyContent="center" gap={2} marginBottom={4}>
            <Chip label={t("question_evaluation.questions") + ` ${test.totalQuestions}`} color="primary" variant="outlined" icon={<BookIcon />} />
            <Chip label={t("question_evaluation.lives") + ` ${test.totalLives}`} color="secondary" variant="outlined" icon={<FavoriteIcon />} />
          </Box>
        </>}

        {test.materials.map((material, index) => {
          const type = material.type.includes("/") ? material.type.split("/")[0] : material.type;
          let component = null;
          switch (type) {
            case 'text':
              component = <Typography key={index} variant="body1" textAlign="center" gutterBottom dangerouslySetInnerHTML={{ __html: material.content }} />;
              break
            case 'video':
              component = <MediaView title={material.name} video={REST.URL(`content/${material.url}`)} />;
              break;
            case 'image':
              component = <MediaView title={material.name} img={REST.URL(`content/${material.url}`)} />;
              break;
            case 'application':
            case 'file':
              component = <MediaView title={material.name} file={REST.URL(`content/${material.url}`)} download={true} />;
              break;
            default:
              return null;
          }

          return <Box key={material.url + material.name} py={2}>
            {component}
          </Box>
        })}

        {/*<SmallMediaCard data={[]} />*/}
        
        <Box mt="auto" display="flex" justifyContent="center" marginTop={4}>
          {intro && <Button variant="contained" color="success" size="large" sx={{ padding: '10px 30px', fontSize: '1.1rem' }} onClick={confirm}>
            {t("question_evaluation.begin_test")}
          </Button>}
        </Box>
      </Paper>
    </Container>
  );
};

export default TestIntro;
