import apiClient from "./apiClient";

// TODO: implementovat progress a řídit co se zobrazuje podle něho


export const getTest = async (testId) => {
  try {
    const response = await apiClient.get(`/test_progress/${testId}?is_first=1`);

    const testData = response.data.general;
    const introFile = testData.files.find(f => f.type === "intro");
    const attachmentFiles = testData.files.filter(f => f.type === "attachment");
    const description = testData.description;

    const questionData = response.data.data;

    const progressData = response.data.progress;

    const test = {
      name: testData.name,
      totalQuestions: testData.totalQuestions,
      totalLives: testData.totalLives,
      test_kit_id: testData.test_kit_id,
      materials: [
        {
          type: "text",
          content: description
        },
        ...(introFile ? [{
          type: introFile.mime.split("/")[0],
          name: introFile.name,
          url: introFile.url
        }] : []),
        ...attachmentFiles.map(f => ({
          type: f.mime.split("/")[0],
          name: f.name,
          url: f.url
        }))
      ]
    }

    return { test, question: questionData, progress: progressData };
  } catch (error) {
    console.error("Chyba při načítání testu:", error);
    throw error;
  }
}

export const getNextQuestion = async (testId) => {
  try {
    const body = {
      next: true
    };
    const result = await apiClient.post(`/test_progress/${testId}`, body);

    return result.data;
  } catch (error) {
    console.error("Chyba při načítání otázky:", error);
    return error;
  }
}

export const evaluateAnswer = async (testId, questionId, userAnswer) => {
  try {
    const response = await apiClient.post(`/test_progress/${testId}`, {
      question: questionId,
      answers: userAnswer
    });
    return response.data;
  } catch (error) {
    console.error("Chyba při vyhodnocení odpovědi:", error);
  }
}

export const createTestInstance = async (testKitId) => {
  try {
    const response = await apiClient.post(`/test_progress/`, {
      createTestInstance: 1,
      testKitId: testKitId
    });
    return response.data;
  } catch (error) {
    console.error("Chyba při vyhodnocení odpovědi:", error);
  }
}