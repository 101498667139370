import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import { createTheme } from '@/theme';
import { AuthProvider } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';
import { Box } from '@mui/material';
import './i18n';
import TestProvider from './contexts/TestContext';
import { FullscreenProvider } from './contexts/FullscreenContext';
import * as Sentry from '@sentry/react';
import { httpClientIntegration } from "@sentry/integrations";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import pkg from "../package.json";
import { FileManagerProvider } from './contexts/FileManagerContext';
import { FileDropProvider } from './contexts/FileDropContext';
import { BrowserRouter } from "react-router-dom";
import React from 'react';
import RTLS from './rtls/rtls';



const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const theme = createTheme();

const sentryUrl = import.meta.env.VITE_SENTRY_URL;

if (sentryUrl) {
  console.log('Running sentry!');
  Sentry.init({
    dsn: sentryUrl,
    tag: "frontend",
    release: "test-platform-frontend@" + pkg.version,
    environment: 'fe:' + import.meta.env.MODE,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      httpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [[500, 599]],

        // This array can contain Regexes, strings, or a combination of both.
        // default: [/.*/]
        // failedRequestTargets: [
        //   "http://example.com/api/test",
        //   /(staging\.)?mypage\.com/,
        // ],
      }),
      Sentry.replayIntegration({
        maskAllText: false
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^\//, /.*/],
    // tracePropagationTargets: [
    //   "third-party-site.com",
    //   /^https:\/\/yourserver\.io\/api/,
    // ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

root.render(
  <BrowserRouter>
    <AppProvider>
      <TestProvider>
        <AuthProvider>
          <FullscreenProvider>
            <FileManagerProvider>
              <FileDropProvider>
                <ThemeProvider theme={theme}>
                  <Box sx={{ display: 'flex', flexDirection: "column", minHeight: "100vh" }}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <App />
                  </Box>
                </ThemeProvider>
              </FileDropProvider>
            </FileManagerProvider>
          </FullscreenProvider>
        </AuthProvider>
      </TestProvider>
    </AppProvider>
  </BrowserRouter>
);