import PropTypes from "prop-types"
import { Box, Typography } from '@mui/material';
import FormTextField from "../Form/TextField";
import { useEffect } from "react";
import Slider from "../Form/Slider";
import { useTranslation } from 'react-i18next';

// TODO: není ošetřeno že není větší a menší 

const RangeAnswer = ({ name, control, watch, setValue, errors }) => {
  const minValue = watch(`${name}.rangeMin`) ?? 1;
  const maxValue = watch(`${name}.rangeMax`) ?? 5;
  const correct = watch(`${name}.answers.0.correct`);
  const id = watch(`${name}.answers.0.id`);

  const correctError = errors?.answers?.[0]?.correct?.message;
  const { t } = useTranslation()

  useEffect(() => {
    setValue(`${name}.answers.0.id`, id || -1);
  }, [setValue, name, id]);


  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mt={2} gap={2}>
        <FormTextField
          control={control}
          name={`${name}.rangeMin`}
          label={t("question_editor.min")}
          inputProps={{
            inputProps: {
              max: maxValue,
            },
            type: "number",
            multiline: false,
            variant: "outlined",
            size: "small"
          }}
        />

        <FormTextField
          control={control}
          name={`${name}.rangeMax`}
          label={t("question_editor.max")}
          inputProps={{
            inputProps: {
              min: minValue,
            },
            type: "number",
            multiline: false,
            variant: "outlined",
            size: "small",
          }}
        />
      </Box>
      <Typography align="center" variant="h4" gutterBottom color={correctError ? "error.main" : "success.main"} >
        {correctError || correct}
      </Typography>

      <Typography variant="body2">
        {t('question_editor.use_slider')}
      </Typography>
      <Slider
        name={`${name}.answers.0.correct`}
        control={control}
        min={minValue}
        max={maxValue}
        defaultValue={null}
        color={correctError ? "red" : undefined}
      />
    </Box>
  );
};

RangeAnswer.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  watch: PropTypes.func
}

export default RangeAnswer;
