/**
 * Kontext pro globální stav zobrazení fullscreen medií
 */
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import useEventListener from '../hooks/useEventListener'
import { broadcastEvent } from '../utils/events';

export const FileManagerContext = createContext();

export function useFileManager(callback) {
  const context = useContext(FileManagerContext);
  useEventListener(`FILE_MANAGER_RESULT`, ({ detail }) => callback && callback(detail))
  return context;
}
export function FileManagerProvider({ children }) {
  const [data, setData] = useState(null)

  const open = (props = {}) => {
    console.log(`[file manager] open`, data, props)
    props.open = true;
    setData(props)
  }

  const close = (result) => {
    if (result) {
      broadcastEvent('FILE_MANAGER_RESULT', result)
      if (data?.callback) data.callback(result)
    }
    setData(null)
  }

  const contextValue = {
    isOpen: data?.open || false,
    pickOne: data?.pickOne || false,
    checked: data?.checked || [],
    selected: data?.selected || [],
    data,
    open,
    close,
  };

  return (
    <FileManagerContext.Provider value={contextValue}>
      {children}
    </FileManagerContext.Provider>
  );
}

FileManagerProvider.propTypes = {
  children: PropTypes.element
}
