import { useEffect } from "react";

export default function useEventListener(name, callback) {

    function handle(event) {
        callback(event);
    }

    useEffect(() => {
        window?.addEventListener(name, handle);
        return () => {
            window?.removeEventListener(name, handle);
        };
    });
}