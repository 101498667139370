import { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Unstable_Grid2 as Grid
} from '@mui/material';
import flags from '../../data/languages.json'

import PropTypes from 'prop-types';

export const AccountLanguageEditing = ({ t, user, changeLanguage, languages, type }) => {

    if (type === `ui`)
        languages = languages?.filter(l => l?.UI_READY)

    return <Card>
        <CardHeader
            title={t(`general.language`) + ` ` + t(type === `ui` ? `general.of_interface` : `general.of_test`)}
        />
        <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
                <Grid
                    xs={12}
                    md={12}
                >
                    <Box sx={{ px: 1 }}>
                        {languages && languages?.map((l, id) => {
                            console.log({ l, id, user })
                            return <Button variant={l?.LANGUAGE_ID == (type === `ui` ? user.UserLangID : user.UserTestLangID) ? `outlined` : ``} sx={{ fontSize: "x-large", padding: 0, minWidth: 44, }} key={id} onClick={() => changeLanguage(type, l)}>
                                {flags[l?.LANGUAGE_ISO_CODE] || l?.LANGUAGE_ISO_CODE}
                            </Button>
                        })}
                    </Box>
                </Grid>
            </Box>
        </CardContent>
    </Card >
};

//props validation
AccountLanguageEditing.propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired,
    handleSignOut: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired,
}
