
import REST from "../utils/rest";
import { Checkbox, SvgIcon, TableCell, TableRow, TextField } from "@mui/material";
import { Icon } from "./icon";
import { useFullscreen } from "../contexts/FullscreenContext";
import { useState } from "react";
import BasicMenu from "./menu";
import { useTranslation } from "react-i18next";
import { toastError } from "../utils/toasts";


const FileManagerItem = ({ rec, selected, handleSelectItem, dialog, recordEdited, recordDeleted, deletable }) => {

    const { t, i18n } = useTranslation()

    const fullscreen = useFullscreen()
    let icon = `image`


    const openItem = (rec) => {

        let tag = `img`;

        switch (rec?.EXTRA_FILE_MIME_TYPE) {
            case `application/pdf`:
                tag = `file`
                break;
            case `video/mp4`:
                tag = `video`
                break;
        }

        console.log(`open item`, { rec, tag })

        fullscreen.open({
            [tag]: REST.URL(`content/${rec?.EXTRA_FILE}`)
        })
    }

    switch (rec?.EXTRA_FILE_MIME_TYPE) {
        case `application/pdf`:
            icon = `DocumentIcon`
            break;
        case `video/mp4`:
            icon = `DocumentIcon`
            break;
    }

    const isItemSelected = selected?.indexOf(rec?.EXTRA_FILE) !== -1
    const labelId = `enhanced-table-checkbox-${rec?.EXTRA_FILE_NAME}`;

    const Editable = ({ record, value, submit }) => {
        const defaultValue = record?.[value];
        const [newValue, setNewValue] = useState(defaultValue)

        const handleEnter = (e) => {
            if (e.key === 'Enter') {
                submit({ ...record, [value]: newValue })
                //e.target.blur()
            }
        };

        return <TextField
            // className="editable"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={handleEnter}
            onBlur={() => {
                if (newValue !== defaultValue) {
                    setNewValue(defaultValue);
                    toastError(t("errors.press_enter_to_save"))
                }
            }}
            variant="standard"
            sx={{ "& .MuiInput-underline:before": { display: "none" } }}
        />
    }

    return <TableRow
        hover
        onClick={() => {
            console.log(`item click`, rec)
            openItem(rec)
        }}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        sx={{ cursor: 'pointer', /* height: 68 */ }}
    // style={{ height: 20 }}
    >
        {dialog && <TableCell padding="none">
            <Checkbox
                size="small"
                color="primary"
                checked={isItemSelected}
                inputProps={{
                    'aria-labelledby': labelId,
                }}
                // onClick={(e) => {
                //     e.stopPropagation()
                //     e.preventDefault()
                // }}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    console.log(`item checkbox chenge`, e)
                    handleSelectItem(e, rec?.EXTRA_FILE)
                }}
            />
        </TableCell>}
        <TableCell>
            {icon === `image` ? (
                <img
                    src={REST.URL(`content/${rec?.EXTRA_FILE}`)}
                    style={{
                        height: 32,
                        width: 32,
                        margin: `auto`,
                        objectFit: `contain`,
                        // borderRadius: 4,
                    }}
                />
            ) : (
                <SvgIcon fontSize="small">
                    <Icon path={icon} />
                </SvgIcon>
            )}
        </TableCell>
        <TableCell>
            <Editable record={rec} value={`EXTRA_FILE_NAME`} submit={recordEdited} />
            {/* {rec?.EXTRA_FILE_NAME} */}
        </TableCell>
        <TableCell>
            {rec?.FILE_SIZE || `---`}
        </TableCell>
        <TableCell>
            {rec?.DATE_CREATED ? new Date(rec?.DATE_CREATED)?.toLocaleString() : `---`}
        </TableCell>
        <TableCell>
            {rec?.DATE_MODIFIED ? new Date(rec?.DATE_MODIFIED)?.toLocaleString() : `---`}
        </TableCell>
        {deletable && <TableCell align="right">
            <BasicMenu
                button={<SvgIcon
                    fontSize="small"
                >
                    <Icon path={`TrashIcon`} />
                </SvgIcon>
                }
                options={[
                    {
                        title: t(`general.delete`),
                        callback: () => recordDeleted(rec)
                    }
                ]}
            />
        </TableCell>}
    </TableRow >
}

export default FileManagerItem;