import PropTypes from "prop-types"
import { Box, IconButton, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

const ImageContainer = styled(Box)(() => ({
  position: 'relative',
  borderRadius: 2,
  '&:hover img': {
    filter: 'brightness(80%)',
    //borderRadius: ,
  },
  '&:hover div': {
    display: 'flex',
  },
}));

const Image = styled('img')({
  width: '100%',
  height: 'auto',
  transition: 'filter 0.3s',
});

const Overlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledIconButton = styled(IconButton)({
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
  },
});

/**
 * React komponenta pro zobrazení obrázku s Overlay na kterém jsou ikony pro editaci a odebrání 
 */
const ImageWithIcons = ({ imageUrl, removeImage, editImage }) => {
  const { t } = useTranslation()
  return (
    <ImageContainer>
      <Image src={imageUrl} alt={t("question_editor.chosen_img")} sx={{ borderRadius: 2 }} />
      <Overlay gap={2}>
        <StyledIconButton onClick={editImage} color="primary">
          <EditIcon />
        </StyledIconButton>
        <StyledIconButton onClick={removeImage} color="error">
          <DeleteIcon />
        </StyledIconButton>
      </Overlay>
    </ImageContainer>
  );
};

export default ImageWithIcons;
