import * as MaterialDesignIcons from '@mdi/js';


export function cssVar(varname) {
    let usablevarname = varname.startsWith(`--`) ? varname : `--${varname}`
    let value = getComputedStyle(document.documentElement).getPropertyValue(usablevarname).trim();
    if (!value || value === ``)
        return varname;
    return value;
}

export async function GetImageSize(url, callback) {

    if (callback) return callback(await GetImageSize(url))

    return new Promise((resolve, reject) => {
        try {
            const img = new Image();
            img.onload = () => resolve({ w: img.width, h: img.height })
            img.src = url;
        } catch (e) {
            reject(e)
        }
    })
}

export function getIcon(icon_name) {
    try {
        return MaterialDesignIcons[icon_name.trim()];
    } catch (e) {
        return null;
    }
}
