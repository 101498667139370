import PropTypes from "prop-types"
import { TextField } from "@mui/material"
import { Controller } from "react-hook-form"

export const FormTextField = ({ name, control, label, inputProps = {} }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <TextField
          {...field}
          helperText={error ? error.message : null}
          error={!!error}
          //onChange={onChange}
          //value={value}
          fullWidth
          label={label}
          variant="outlined"
          multiline
          {...inputProps}
        />
      )}
    />
  )
}

FormTextField.propTypes = {
  control: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string
}

export default FormTextField;