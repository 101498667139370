import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import { Box, Button, Container, SvgIcon, Typography } from '@mui/material';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const Page = ({ img, title, message }) => (
    <>
        <Box
            component="main"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                minHeight: '100vh',
            }}
        >
            <Container maxWidth="md">
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {img && <Box
                        sx={{
                            mb: 3,
                            textAlign: 'center'
                        }}
                    >
                        <img
                            alt="Under development"
                            src={img}
                            style={{
                                display: 'inline-block',
                                maxWidth: '100%',
                                width: 400
                            }}
                        />
                    </Box>}
                    {title && <Typography
                        align="center"
                        sx={{ mb: 3 }}
                        variant="h3"
                    >
                        {title}
                    </Typography>}
                    {message && <Typography
                        align="center"
                        color="text.secondary"
                        variant="body1"
                    >
                        {message}
                    </Typography>}
                    <Button
                        component={Link}
                        to="/login"
                        startIcon={(
                            <SvgIcon fontSize="small">
                                <ArrowLeftIcon />
                            </SvgIcon>
                        )}
                        sx={{ mt: 3 }}
                        variant="contained"
                    >
                        {t(`general.back_to_homepage`)}
                    </Button>
                </Box>
            </Container>
        </Box>
    </>
);

export default Page;
