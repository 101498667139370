import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { logOut } from '../../services/authService';
import { toastError } from '../../utils/toasts';
// import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import flags from '../../data/languages.json'
import REST from '../../utils/rest';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const { login, logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation()
  const [languages, setLanguages] = useState()

  const getLanguages = useCallback(async () => {
    const [success, newdata] = await REST.GET(`languages`)
    if (!success) toastError()
    setLanguages(newdata?.Records)
  }, [])

  useEffect(() => {
    getLanguages()
  }, [])

  useEffect(() => {
    if (open) getLanguages()
  }, [open])

  // useEffect(() => {
  //   const possible_default_lang = handleStorage(`get`, `ask_to_set_default_after_reload`)
  //   if (possible_default_lang) {
  //     handleStorage(`remove`, `ask_to_set_default_after_reload`)
  //     console.log(`ask_to_set_default`, possible_default_lang)
  //     toast((ts) => (<span>
  //       {t(`general.language`)} {t(`general.changed`)}
  //       <Button onClick={async () => {
  //         await changeLanguage(possible_default_lang, true)
  //         toast.dismiss(ts.id)
  //       }}>
  //         {t(`general.set_as_default`)}
  //       </Button>
  //     </span>));
  //   }
  // }, [])

  const sendLanguage = async (language, set_as_default, type) => await REST.POST(`ajax/set_language`, { language, set_as_default, type })

  const changeLanguage = async (type, l, set_as_default = true) => {

    console.log(`language change initiated`, l, set_as_default, currentUser)

    await sendLanguage(l?.LANGUAGE_ID, set_as_default, type)//posílám jazyk do be

    //nabízím nastavit jako výchozí
    toast((ts) => (<span>
      {t(`general.language`)} {t(`general.changed`)}
    </span>));

    if (set_as_default) {
      console.log(`changeLanguage......`, currentUser)
      currentUser.UserLangID = l?.LANGUAGE_ID;
      currentUser.UserLangISOCode = l?.LANGUAGE_ISO_CODE;
      login(currentUser)
    } else
      i18n.changeLanguage(l?.LANGUAGE_ISO_CODE)

    window.location.reload()
  }

  const handleSignOut = useCallback(
    async () => {
      onClose?.();
      const result = await logOut()
      if (result) {
        logout();
        navigate(`/login`)
        toast.success(t("general.logout_msg"), { duration: 4000, position: "top-center" });
      }
      else toastError(t(`auth.logout_failed`))
      //router.push('/auth/login');
    },
    [onClose, logout, navigate]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="overline">
          {t(`menu.account`)}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {currentUser?.UserFullName || "???"}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          py: 1.5,
          px: 0
        }}>
        <Typography paddingLeft={2} variant="overline">
          {t(`general.language`)} {t(`general.of_interface`)}
        </Typography>
        <Box sx={{ px: 1 }}>
          {languages && languages?.filter(x => x?.UI_READY)?.map((l, id) => {
            return <Button variant={l?.LANGUAGE_ID == currentUser?.UserLangID ? `outlined` : ``} sx={{ fontSize: "x-large", padding: 0, minWidth: 44, }} key={id} onClick={() => changeLanguage(`ui`, l)}>
              {flags[l?.LANGUAGE_ISO_CODE] || l?.LANGUAGE_ISO_CODE}
            </Button>
          })}
        </Box>
      </Box>
      <Box
        sx={{
          py: 1.5,
          px: 0
        }}>
        <Typography paddingLeft={2} variant="overline">
          {t(`general.language`)} {t(`general.of_test`)}
        </Typography>
        <Box sx={{ px: 1 }}>
          {languages && languages.map((l, id) => {
            return <Button variant={l?.LANGUAGE_ID == currentUser?.UserTestLangID ? `outlined` : ``} sx={{ fontSize: "x-large", padding: 0, minWidth: 44, }} key={id} onClick={() => changeLanguage(`test`, l)}>
              {flags[l?.LANGUAGE_ISO_CODE] || l?.LANGUAGE_ISO_CODE}
            </Button>
          })}
        </Box>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        <MenuItem onClick={handleSignOut}>
          {t(`general.logout`)}
        </MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
