import moment from 'moment';

export function dateCZ(date) {
    if (!date) return `---`;
    return moment(date).format("DD.MM.YYYY");
}

export function datetimeCZ(date) {
    if (!date) return `---`;
    return moment(date).format("DD.MM.YYYY HH:mm:ss");
}