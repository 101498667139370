import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { withAuthGuard } from '@/hocs/with-auth-guard';
import { SideNav } from './side-nav';
import { TopNav } from './top-nav';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import Loader from '../../components/loader2';


const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

export const Layout = withAuthGuard((props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [openNav, setOpenNav] = useState(lgUp);

  const handlePathnameChange = useCallback(
    () => {
      if (openNav) {
        setOpenNav(false);
      }
    },
    [openNav]
  );

  useEffect(
    () => {
      if (!lgUp)
        handlePathnameChange();
      else if (lgUp)
        setOpenNav(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, lgUp]
  );

  useEffect(() => {
    if (lgUp) setOpenNav(true)
  }, [lgUp])

  return (
    <>
      <TopNav onNavOpen={() => setOpenNav(true)} />
      <SideNav
        onClose={() => setOpenNav(false)}
        open={openNav}
      />
      <Loader />
      <LayoutRoot>
        <LayoutContainer>
          {children}
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
});
