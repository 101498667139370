export function handleStorage(action, name, value) {
    let result = null;

    // Pokud je typ hodnoty object tak jej převedu na string
    if (typeof value === "object") value = JSON.stringify(value);

    try {
        switch (action) {
            case "get":
                // Pokusím se získanou hodnotu rozparsovat a pokud se to nepovede tak jí vracím v původním stavu
                try { result = JSON.parse(localStorage.getItem(name)); }
                catch (e) { result = localStorage.getItem(name); }

                break;
            case "set": result = localStorage.setItem(name, value); break;
            case "remove": result = localStorage.removeItem(name); break;
            default: break;
        }
    } catch (e) {
        console.error("Funkce localStorage selhala. S pamětí nelze pracovat.");
    }

    return result;
}

export function storageGet(name) {
    return handleStorage(`get`, name)
}

export function storageSet(name, value) {
    return handleStorage(`set`, name, value)
}

export function storageRemove(name) {
    return handleStorage(`remove`, name)
}