import PropTypes from "prop-types"
import { Card, CardContent, CardHeader } from '@mui/material'
import ListAnswer from './ListAnswer'
import RangeAnswer from './RangeAnswer'
import OrderAnswer from './OrderAnswer'
import ListImageAnswer from './ListImageAnswer'
import DropdownAnswer from './DropDownAnswer'
import { useTranslation } from 'react-i18next';

const AnswersCard = ({ type, data }) => {
  const typeId = type.id;
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader
        title={t("question_editor.answers")}
        subheader={t(type?.description)}
      />
      <CardContent sx={{ pt: 0 }}>
        {typeId === "closedimg" && <ListImageAnswer {...data} />}
        {typeId === "closed" && <ListAnswer {...data} />}
        {typeId === "range" && <RangeAnswer {...data} />}
        {typeId === "order" && <OrderAnswer {...data} />}
        {typeId === "dropdown" && <DropdownAnswer {...data} />}
      </CardContent>
    </Card>
  );
};

export default AnswersCard