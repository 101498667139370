import { t } from "i18next";

export default function timeRemaining(date_due, translate = t) {
    const due = new Date(date_due);
    const now = new Date();
    const diff = due - now;

    if (diff < 0) return `${translate(`general.time_is_up`)}`

    const d = Math.floor(diff / (1000 * 60 * 60 * 24));
    const h = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const m = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    // const s = Math.floor((diff % (1000 * 60)) / 1000);

    return `${translate(`general.remaining_time`)} ${d}d ${h}h ${m}m`;
}
