/**
 * Kontext pro globální stav přihlášení uživatele 
 */
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { checkIfLogged } from '../services/authService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [requestEmail, setRequestEmail] = useState(false);
  const { i18n } = useTranslation()
  const navigate = useNavigate();

  const getUser = useCallback(async () => {
    const user = await checkIfLogged();
    //console.log('isLogged', user)
    if (user) {
      login(user);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    //START APLIKACE
    if (window.location.href.includes('/change_password')) return;
    if (window.location.href.includes('/map')) return;
    getUser()
  }, []);

  const login = (user) => {
    //console.log('login', user)
    setCurrentUser(user);
    if (!user?.UserEmail)
      setRequestEmail(true)
    i18n.changeLanguage(user?.UserLangISOCode)
    localStorage.setItem("user", JSON.stringify(user));
  };

  const logout = () => {
    setCurrentUser(null);
    localStorage.setItem("user", "");
    navigate("/login");
  };

  const contextValue = {
    isAuthenticated: !!currentUser,
    isLoading: true, // TODO: tady stav načtení dat
    currentUser,
    user: currentUser,
    requestEmail,
    setRequestEmail,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.element
}
