import PropTypes from 'prop-types';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Logo } from '@/components/logo';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import TopNav from '../dashboard/top-nav';

// TODO: Change subtitle text

export const Layout = ({ children }) => {

  const { t } = useTranslation();

  return (
    <>
      {/* <TopNav /> */}
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto'
        }}
      >
        <Grid
          container
          sx={{ flex: '1 1 auto' }}
        >
          <Grid
            xs={12}
            lg={6}
            sx={{
              backgroundColor: 'background.paper',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative'
            }}
          >
            {children}
          </Grid>
          <Grid
            xs={12}
            lg={6}
            sx={{
              alignItems: 'center',
              background: 'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',

              justifyContent: 'center',
            }}
          >

            <Box
              component={Link}
              href="/"
              sx={{
                display: 'inline-flex',
                height: 60,
                width: 199
              }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  height: 50, 
                  width: "auto",
                  marginTop: -2
                }}
              >
                <img
                  src={"/assets/logo/eduway_logo.png"}
                  alt="Eduway Logo"
                  style={{ height: '90px', width: 'auto' }} 
                />
              </Box>
            </Box>
            <Box sx={{ p: 3 }}>
              <Typography
                align="center"
                color="inherit"
                sx={{
                  fontSize: '24px',
                  lineHeight: '32px',
                  mb: 1
                }}
                variant="h1"
              >
                <Trans i18nKey={"loginPage.welcome.main"}>
                  <Box
                    component="a"
                    sx={{ color: '#15B79E' }}
                    target="_blank"
                  >
                    PaSCom
                  </Box>
                </Trans>
              </Typography>
              <Typography
                align="center"
                sx={{ mb: 3 }}
                variant="subtitle1"
              >
                {t("loginPage.welcome.description")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};