import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

export const AppContext = createContext();

export function useAppContext() {
  return useContext(AppContext);
}

export function AppProvider({ children }) {
  const [sideNavIsOpen, setSideNavIsOpen] = useState(false);

  const openSideNav = () => {
    setSideNavIsOpen(true);
  }

  const closeSideNav = () => {
    setSideNavIsOpen(false);
  }


  const contextValue = {
    sideNavIsOpen,
    openSideNav,
    closeSideNav

  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.element
}