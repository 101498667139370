
import {  Button, Card, CardHeader, SvgIcon } from "@mui/material";
import { ArrowUpTrayIcon,  } from "@heroicons/react/24/solid";
// import { t } from "i18next";
import { useFileDrop } from "../contexts/FileDropContext";
import FileManagerTable from "./file-manager-table";
import { useTranslation } from "react-i18next";


const FileManager = ({ dialog, onClose, pickOne, checked }) => {

    const { t } = useTranslation()
    const fileDrop = useFileDrop()

    return <Card>
        <CardHeader
            title={t("file_manager.files")}
            action={<>
                <Button
                    color="primary"
                    endIcon={(
                        <SvgIcon fontSize="small">
                            <ArrowUpTrayIcon />
                        </SvgIcon>
                    )}
                    size="medium"
                    variant="text"
                    onClick={() => fileDrop?.open()}
                >
                    {t(`file_manager.upload`)} {t(`file_manager.files`)}
                </Button>
            </>}
        />
        <FileManagerTable
            onClose={onClose}
            dialog={dialog}
            pickOne={pickOne}
            checked={checked}
        />
    </Card >
};

export default FileManager;
