// @ts-check
import React, { useCallback, useState } from 'react';
import { Box, Card, CardContent, Container, Typography, Button, IconButton, CardHeader, TableBody, TableContainer, Table, TableHead, TableRow, TableCell, Grid, TextField } from '@mui/material';


import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
// import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
// import { TestKitEditorProvider } from '../../contexts/TestKitEditorContext';
// import ConfirmDialog from '../ConfirmDialog';
import { useTestKitEditor } from '../../hooks/useTestKitEditor';
import { useFileManager } from '../../contexts/FileManagerContext';
import FileManagerItem from '../file-manager-table-item';
import { useTranslation } from 'react-i18next';
import SaveButton from '../SaveButton';
import LanguageTabs from '../QuestionEditor/LanguageTabs';
import Loader from '../loader2';
import { Label } from '@mui/icons-material';

const VariantForm = ({ currentVariant, editVariant, removeVariant, openFileManager }) => {

  const { t } = useTranslation();

  //const noFiles = !currentVariant?.files || currentVariant?.files.length === 0;

  const columns = {
    EXTRA_FILE_NAME: t(`general.name`),
    FILE_SIZE: t(`general.size`),
    DATE_CREATED: `${t(`general.date`)} ${t(`general.of_creation`)}`,
    DATE_MODIFIED: `${t(`general.date`)} ${t(`general.of_change`)}`,
  }

  return (
    <>
      <Card sx={{ marginTop: 4 }}>
        <CardHeader
          title={t(`DESCRIPTION`)}
        />
        <CardContent>
          <TextField
            // label="Outlined"
            placeholder={t('question_editor.here_enter_desc')}
            variant="outlined"
            sx={{ width: "100%" }}
            multiline
            rows={8}
            value={currentVariant?.text || ""}
            onChange={(e) => editVariant("text", e.target.value)}
          />
        </CardContent>
      </Card>

      <Grid container direction={{ md: "row" }} mb={4}>
        <Card sx={{ marginTop: 4 }}>
          <CardHeader
            title={t("question_editor.attachments")}
            action={<Button variant="contained" onClick={() => openFileManager()}>{t(`general.edit`)}</Button>}

          />
          <TableContainer style={{ maxHeight: 600, width: 800 }}>
            <Table>
              <caption>{t(`test_kit_editor.here_add_attachments`)}</caption>

              <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
                <TableRow>
                  <TableCell></TableCell>
                  {Object.keys(columns)?.map((col, id) => {
                    return <TableCell
                      key={id}
                    >
                      {columns[col]}
                    </TableCell>
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentVariant?.files?.map((file, index) => {
                  return <FileManagerItem
                    key={index}
                    rec={{
                      EXTRA_FILE_MIME_TYPE: file?.mime || file?.EXTRA_FILE_MIME_TYPE,
                      EXTRA_FILE_ID: file?.id || file?.EXTRA_FILE_ID,
                      EXTRA_FILE_NAME: file?.name || file?.EXTRA_FILE_NAME,
                      EXTRA_FILE: file?.url || file?.EXTRA_FILE
                    }}
                  />
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
      <Box display="flex" gap={3} flex={1} mb={4}>
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <Grid container direction={{ md: "row" }}>
              <Grid item md={4}>
                <Typography gutterBottom variant="h6">{t(`question_editor.remove_variant`)}</Typography>
              </Grid>
              <Grid item md={8}>
                <Typography variant="subtitle1">{t(`question_editor.remove_language_variant_alert`)}</Typography>
                <Button sx={{ mt: 3 }} variant="outlined" color="error" onClick={removeVariant}>{t(`question_editor.remove_variant`)}</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

const TestKitEditor = () => {
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);

  const { testKitID } = useParams();
  const navigate = useNavigate();

  // const { t } = useTranslation();
  const { testKit, languages, variants, state, save, addVariant, removeVariant, editVariant, tab, setTab, currentVariant = {} } = useTestKitEditor(testKitID);

  const fileManager = useFileManager();

  if (!variants) return;

  const openFileManager = () => {
    fileManager.open({
      pickOne: false,
      checked: currentVariant.files?.map(x => x.url),
      callback: (data) => {
        editVariant("files", data.map(i => ({
          id: i.EXTRA_FILE_ID,
          name: i.EXTRA_FILE_NAME,
          url: i.EXTRA_FILE,
          mime: i.EXTRA_FILE_MIME_TYPE,
        })))
      }
    });
  }

  const back = () => {
    navigate(-1);
  };


  const activeVariants = variants?.map(v => v?.language);

  const tabs = variants.map((v, index) => {
    const currentLang = languages.find(i => i.id === v?.language);
    //const error = errors?.variants?.[index];
    //const error = false;
    const sx = {
      padding: 2,
      marginLeft: "24px",
      /* ...(error && {
        color: "error.main !important",
        backgroundColor: "error.light",
        '&.Mui-selected': {
          color: "red"
        },
      }), */
    };
    return { id: currentLang?.id, label: currentLang?.name, sx };
  })

  /* const removeVariant = useCallback((index) => {
    const variant = { ...variants[index] };
    //if (!variant.new_id)
    //appendRemoved(variant);

    //removeFromVariants(index);
    toast.success("Varianta byla úspěšně odebrána.");
    setTab(index - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [variants, appendRemoved, removeFromVariants]) */

  const NoVariant = () => {
    const { t, i18n } = useTranslation()
    return <Box sx={{ mt: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>
      <Typography variant="h5">{t(`errors.no_variant`)}</Typography>
      <Button variant="contained" size="large" onClick={() => setLanguageDialogOpen(true)}>{t(`question_editor.add_variant`)}</Button>
    </Box>
  }

  const noVariants = variants.length === 0;

  return (
    <Container>
      <Loader />
      <Box mt={2} position="sticky" top={0} zIndex={10}>
        <Card>
          <CardContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center" gap={2}>
                <IconButton onClick={back} size="large" ><ArrowBackRoundedIcon /></IconButton>
                <Typography variant='h4'>{testKit?.TEST_KIT_NAME}</Typography>
              </Box>
              <Box gap={2} display="flex">
                <SaveButton disabled={false} state={state} onClick={save} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <LanguageTabs
        tabs={tabs}
        languages={languages}
        setTab={setTab}
        currentTab={tab}
        activeVariants={activeVariants}
        addVariant={addVariant}
        dialogOpen={languageDialogOpen}
        setDialogOpen={setLanguageDialogOpen}
      />


      {
        noVariants
          ? <NoVariant />
          : <VariantForm
            currentVariant={currentVariant}
            removeVariant={removeVariant}
            editVariant={editVariant}
            openFileManager={openFileManager}
          />
      }

    </Container >
  )
}

export default TestKitEditor
