import PropTypes from "prop-types"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from "react-i18next"

const ConfirmDialog = ({
  open = false,
  title,
  text,
  confirm,
  confirmButtonLabel,
  onClose
}) => {

  const { t, i18n } = useTranslation()

  const handleConfirm = () => {
    confirm();
    onClose();
  }

  return (
    <Dialog open={open} color="error.light" onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>{t(`general.cancel`)}</Button>
        <Button variant="contained" color="error" onClick={() => handleConfirm()}>{confirmButtonLabel}</Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  confirm: PropTypes.func,
  confirmButtonLabel: PropTypes.string,
  onClose: PropTypes.func
}

export default ConfirmDialog