import { useState, useEffect } from 'react';
import { getQuestionById, updateQuestion as updateQuestionService } from '../services/questionEditor.service';

export const useQuestionEditor = (questionId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getQuestionById(questionId);
        //  TODO: odebrat - pouze demo (řadit by měl backend)
        //if (result?.question?.type === "order") {
        //  result.variants[0].answers = result.variants[0].answers.sort((a, b) => a.correct - b.correct)
        // }
        setData(result);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [questionId]);

  const updateQuestion = async (updatedData) => {
    try {
      setLoading(true);
      const result = await updateQuestionService(questionId, updatedData);
      if (result?.question?.type === "order") {
        result.variants[0].answers = result.variants[0].answers.sort((a, b) => a.correct - b.correct)
      }
      setData(result);
      return true;
    } catch (e) {
      setError(e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const clearError = () => {
    setError(false);
  }

  return { data, loading, error, updateQuestion, setData, clearError };
};
