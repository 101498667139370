import PropTypes from "prop-types";
import { Check, CloseRounded } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';

const SaveButton = ({ state, onClick, disabled }) => {
  const { t } = useTranslation()

  const buttonStates = {
    "saved": { label: t("question_editor.saved"), icon: <Check size={20} />, color: "success" },
    "loading": { label: t("question_editor.saving"), icon: <CircularProgress size={20} />, color: "primary" },
    "ready": { label: t("question_editor.save_changes"), color: "warning" },
    "waiting": { label: t("question_editor.save_changes"), color: "warning" },
    "failed": { label: t("question_editor.save_failed"), icon: <CloseRounded size={20} />, color: "error" }
  }

  const button = buttonStates?.[state];

  return (
    <Button
      variant="contained"
      color={button?.color}
      disabled={state === "waiting" || state === "loading" || (state !== "saved" && disabled)}
      onClick={onClick}
      endIcon={button?.icon || null}
      sx={{
        pointerEvents: state === "saved" ? "none" : "auto"
      }}
    >
      {button?.label}
    </Button>
  )
}

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  state: PropTypes.oneOf(["saved", "loading", "ready", "failed"]).isRequired
}

export default SaveButton;
