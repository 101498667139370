import { Slider } from '@mui/material'
import { Controller } from 'react-hook-form'

const FormSlider = ({ name, control, color, min = 1, max = 3, step = 1, defaultValue }) => {
  return (<Controller
    name={name}
    control={control}
    render={({
      field,
    }) => (
      <Slider
        {...field}
        defaultValue={defaultValue}
        min={parseInt(min)}
        max={parseInt(max)}
        valueLabelDisplay="off"
        step={1}
        style={{
          color: color || '#1976d2', // Primární barva pro slider
          height: '8px'     // Tloušťka slideru
        }}
        marks={
          [
            { value: parseInt(min), label: min?.toString() },
            { value: parseInt(max), label: max?.toString() },
          ]}
      />
    )}
  />
  )
}

export default FormSlider