import { useCallback, useEffect, useState } from 'react';
import REST from '../utils/rest';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

/**
 * @typedef Language
 * @prop {number} id
 * @property {string} name
 * @property {string} iso
 */

/**
 * @typedef File Příloha
 * @property {number} id ID přílohy
 * @property {string} mime MIME type přílohy
 * @property {string} name název přílohy
 * @property {string} url URL přílohy
 */

/**
 * @typedef TestKitVariant
 * @property {number} id ID varianty
 * @property {number} language ID jazyka varianty
 * @property {string} description Popis varianty
 * @property {Array<File>} files Přílohy varianty
 */

/**
 * @typedef useTestKitEditorObject
 * @property {Array<TestKitVariant>} variants - Pole variant testovací sady.
 * @property {Array<Language>} languages - Pole jazyků testovací sady.
 * @property {Function} refresh - Funkce pro obnovení dat testovací sady.
 * @property {Function} save - Funkce pro uložení změn v testovací sadě.
 * @property {'waiting' | 'loading' | 'saved' | 'failed'} state - Stav načítání
 */


/**
 * Vlastní React hook pro správu testovací sady.
 * @param {*} id - ID testovací sady.
 * @returns {useTestKitEditorObject} - Objekt obsahující data testovací sady, funkci pro obnovení dat, funkci pro uložení změn a stav načítání.

 */
export function useTestKitEditor(id) {
    const [testKit, setTestKit] = useState({});
    const [variants, setVariants] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [tab, setTab] = useState(0);
    const [loadState, setLoadState] = useState('waiting');
    const { t } = useTranslation()
    const currentVariant = variants[tab];

    const getData = useCallback(async () => {
        const [success, result] = await REST.GET(`test_kit_edit/${id}`);
        if (success) {
            setVariants(result.variants);
            setLanguages(result.languages);
            setTestKit(result.test_kit);
        }
    }, [id]);

    useEffect(() => {
        getData()
    }, [getData]);

    const save = async () => {
        setLoadState('loading');
        const [success, result] = await REST.PUT(`test_kit_edit/${id}`, { variants });
        console.log('success', success)
        setLoadState(success ? 'saved' : 'failed');
        if (success && result?.variants) {
            toast.success(t("question_editor.saving_successfull"));
            setVariants(result.variants);
        } else {
            toast.error(t("question_editor.error_occured"));
        }
    };

    const addVariant = (langId) => {
        if (loadState !== 'ready') setLoadState('ready');
        setVariants(prev => ([...prev, { new_id: Date.now(), language: langId }]))
        setTab(variants.length)
    }

    const removeVariant = () => {
        if (loadState !== 'ready') setLoadState('ready');
        setTab(variants.length - 2);
        setVariants(prev => prev.filter(i => i?.language != currentVariant?.language));
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    const editVariant = (name, value) => {
        if (loadState !== 'ready') setLoadState('ready');
        setVariants(prev => prev.reduce((prev, curr) => {
            const current = curr?.language === currentVariant?.language;
            prev.push(current ? { ...curr, [name]: value } : curr);
            return prev
        }, []))
    }

    return {
        testKit,
        variants,
        languages,
        currentVariant,
        refresh: getData,
        save,
        state: loadState,
        tab,
        setTab,
        addVariant,
        removeVariant,
        editVariant
    };
}