import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { Box, SvgIcon, Typography, styled } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { Save } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const StyledFooter = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSuccess" && prop !== "isAnswered"
})(({ theme, isSuccess }) => ({
  backgroundColor: isSuccess !== null ? (isSuccess ? theme.palette.success.lightest : theme.palette.error.lightest) : null,
  color: isSuccess !== null ? (isSuccess ? theme.palette.success.main : theme.palette.error.main) : null
}));

const ContinueButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: "1.2rem"
}))

//QuestionMarkCircleIcon
const Footer = ({ onConfirm, isAnswered, nextQuestion, isSuccess = null, isLoading, hasAnswer }) => {
  const { t, i18n } = useTranslation()


  const StatusIcon = isSuccess ? CheckCircleIcon : XCircleIcon;
  const StatusText = isSuccess ? "general.correct" : "question_evaluation.fail";

  return (
    <StyledFooter sx={{ p: { sm: 2, md: 2, lg: 3 } }} isAnswered={isAnswered} isSuccess={isSuccess}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ flex: 1, display: "inline-flex", alignItems: "center", paddingRight: "20px"}}>
          {isSuccess !== null &&
            <>
              <SvgIcon sx={{ fontSize: "3rem", marginX: 1 }}><StatusIcon /></SvgIcon>
              <Typography variant="h4">{t(StatusText)}</Typography>
            </>}
        </Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
          {isAnswered
            ? <ContinueButton variant="contained" size="large" color={isSuccess ? "success" : "error"} onClick={nextQuestion}>{t(`loginPage.continue`)}</ContinueButton>
            : <ContinueButton disabled={!hasAnswer} loadingPosition="start" startIcon={isLoading ? <Save sx={{ color: "transparent" }} /> : <></>} loading={isLoading} variant="contained" size="large" onClick={onConfirm}>{isLoading ? t("question_evaluation.checking") : t("question_evaluation.check")}</ContinueButton>
          }
        </Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          {/*<Button color="warning" variant="contained" size="large" onClick={nextQuestion}>Další</Button>*/}
        </Box>
      </Box>
    </StyledFooter>)
}

export default Footer