import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';
import flags from '../../data/languages.json'

import PropTypes from 'prop-types';
import REST from '../../utils/rest';
import { toastDismiss, toastError, toastLoading, toastSuccess } from '../../utils/toasts';

export const AccountProfileDetails = ({ t, user, login, logout, navigate, changeLanguage, handleSignOut, languages }) => {
  console.log(`AccountProfileDetails`, { user, languages })
  const [values, setValues] = useState({
    firstName: user.UserFirstname,
    lastName: user.UserSurname,
    email: user.UserEmail,
  });
  const [blockSubmit, setBlockSubmit] = useState(false)

  const handleChange = useCallback(
    (event) => {
      setValues((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    },
    []
  );

  const handleSubmit = useCallback(
    async (event) => {
      setBlockSubmit(true)
      event.preventDefault();
      const loading = toastLoading()
      const [success, result] = await REST.POST(`ajax/change_user_details`, values)
      toastDismiss(loading)
      setBlockSubmit(false)

      if (!success)
        return toastError(t(result?.message))

      login(result)

      toastSuccess(t(`account.details_changed`))

    }, [values]
  );

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <Card>
        <CardHeader
          title={t(`account.personal_info`)}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  // helperText="Please specify the first name"
                  label={t(`U_NAME`)}
                  name="firstName"
                  onChange={handleChange}
                  // required
                  value={values.firstName}
                  disabled
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label={t(`SURNAME`)}
                  name="lastName"
                  onChange={handleChange}
                  // required
                  value={values.lastName}
                  disabled
                />
              </Grid>
              <Grid
                xs={12}
              // md={6}
              >
                <TextField
                  fullWidth
                  label={t(`EMAIL`)}
                  name="email"
                  onChange={handleChange}
                  // required
                  value={values.email}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button disabled={blockSubmit} variant="contained" type='submit'>{t(`general.save`)}</Button>
        </CardActions>
      </Card>
    </form >
  );
};

//props validation
AccountProfileDetails.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
}
