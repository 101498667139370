import PropTypes from "prop-types"
import { Add, DragHandleRounded, RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useFieldArray } from 'react-hook-form';
import FormTextField from '../Form/TextField';
import { useTranslation } from 'react-i18next';

const removeRHFId = (field) => {
  delete field.rhf_id;
  return field;
}

const useAnswersList = ({ name, control }) => {
  const sharedProps = { control, keyName: "rhf_id" };
  const { t } = useTranslation()
  const fieldAnswers = useFieldArray({ ...sharedProps, name: `${name}.answers` });
  const { fields, append, move, remove, replace } = fieldAnswers;

  const fieldRemoved = useFieldArray({ ...sharedProps, name: `${name}.removed_answers` });
  const { append: appendRemoved } = fieldRemoved;

  /**
   * Přidání nové odpovědi 
   */
  const addItem = useCallback(() => {
    const newIndex = fields.length;
    append({ id: -1, text: t("question_editor.text"), correct: newIndex, updated: true });
  }, [append, fields]);

  /**
   * Odebrání odpovědi 
   */
  const removeItem = useCallback((index) => {
    const item = fields[index];
    // Pokud není nový tak jej musím přidat do pole `removed_answers` abych to předal na serveru
    if (typeof item.id !== "undefined")
      appendRemoved(item);

    remove(index);
  }, [remove, appendRemoved, fields]);

  /**
   * Zpracování při přesunu
   */
  const handleDragEndDnD = useCallback((result) => {
    if (!result.destination) {
      console.log("no destination")
      return;
    }
    move(result.source.index, result.destination.index);
  }, [move]);

  /**
   * useEffect se spustí pokaždé když se změní počet odpovědí aby přepočítal správné pořadí podle indexů
   */
  useEffect(() => {
    console.log("changed ???");
    let changed = fields.some((i, index) => i.correct !== index);

    if (!changed)
      return console.log("nothing changed");

    const fixedOrder = fields.map((field, index) => field.correct !== index ? ({ ...removeRHFId(field), correct: index, updated: true }) : removeRHFId(field));
    console.log("order fixed", fixedOrder);

    replace(fixedOrder);
  }, [replace, fields]);

  return {
    fields,
    addItem,
    removeItem,
    handleDragEndDnD
  }
}


/**
 * OrderAnswer
 * funkční pokud splňuje
 * - při jakékoliv úpravě se správně přepočítají všechny indexy (index === correct)
 * - když odeberu nějaký item který má id tak se přidá do answers_removed
 * - když přidám nějaký item a hned ho odeberu tak není ani v answers ani v answers_removed
 */
const ListAnswer = ({ name, control, errors, setValue }) => {
  const { fields, addItem, removeItem, handleDragEndDnD } = useAnswersList({ name, control, setValue });
  const { t } = useTranslation()
  return (
    <DragDropContext onDragEnd={handleDragEndDnD}>
      {errors?.answers?.type === "min" &&
        <Typography align="center" p={2} variant="h5" color="error">
          {t('question_editor.add_at_least_one_answer')}
        </Typography>
      }
      <Droppable droppableId={`${name}-answers`}>
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {fields.map((answer, index) => (
              <Draggable key={answer.rhf_id} draggableId={String(answer.rhf_id)} index={index}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                  >
                    <IconButton disableRipple {...provided.dragHandleProps}>
                      <DragHandleRounded />
                    </IconButton>
                    <FormTextField
                      name={`${name}.answers.${index}.text`}
                      control={control}
                      inputProps={{
                        fullWidth: true,
                        variant: "outlined",
                        margin: "normal"
                      }}
                    />
                    <IconButton title="Odebrat" onClick={() => removeItem(index)}>
                      <RemoveCircleOutline color="error" />
                    </IconButton>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
      <Box px={6} mt={2}>
        <Button variant="contained" size="large" fullWidth startIcon={<Add />} onClick={addItem}>
          {t('question_editor.add_answer')}
        </Button>
      </Box>
    </DragDropContext>
  );
};

ListAnswer.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.any,
  name: PropTypes.string
}

export default ListAnswer;
