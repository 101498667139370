import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Divider, Unstable_Grid2 as Grid, LinearProgress, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '@/layouts/dashboard/layout';
import REST from '../utils/rest';
import { useCallback, useEffect, useState } from 'react';
import { toastError, toastSuccess } from '../utils/toasts';
import { useNavigate } from 'react-router-dom';
import DateRangePickerEmbeded from '../components/date-range-picker';
import { useTranslation } from 'react-i18next';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import SimpleBar from 'simplebar-react';

const DateRange = ({ callback }) => {
  return <DateRangePickerEmbeded callback={callback} />
}

const QuestionsTable = ({ title, tests, tests_groups, tests_users, navigate, t }) => {

  const data = tests || tests_groups || tests_users || []

  return <Grid xs={12}>
    <Card sx={{ height: '100%' }}>
      <CardHeader title={title} />
      <SimpleBar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t(`general.question`)}
                </TableCell>
                <TableCell>
                  {t(`general.question_type`)}
                </TableCell>
                <TableCell>
                  {t(`question_evaluation.total_displayed`)}
                </TableCell>
                <TableCell>
                  {t(`question_evaluation.percent_displayed`)}
                </TableCell>
                <TableCell>
                  {t(`question_evaluation.total_successful`)}
                </TableCell>
                <TableCell>
                  {t(`question_evaluation.percent_successful`)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((rec, index) =>
                <TableRow
                  hover
                  key={index}
                  // style={{ cursor: `pointer` }}
                  //Prozatím vypnuté
                  //onClick={() => navigate(`/test/${rec?.ATTEMPT_LAST?.TEST_HISTORY_ID}`)}
                >
                  <TableCell>
                    {rec.QUESTION_NAME}
                  </TableCell>
                  <TableCell>
                    {rec.QUESTION_TYPE}
                  </TableCell>
                  <TableCell>
                    {rec.TOTAL_DISPLAYED}
                  </TableCell>
                  <TableCell>
                    {rec.PERCENT_DISPLAYED}%
                  </TableCell>
                  <TableCell>
                    {rec.TOTAL_CORRECT}
                  </TableCell>
                  <TableCell>
                    {rec.PERCENT_CORRECT}%
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {data?.length === 0 && <Typography align='center' style={{ width: `100%`, padding: 32 }} >{t(`general.no_records`)}</Typography>}
        </Box>
      </SimpleBar>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
      </CardActions>
    </Card>
  </Grid>
}

const Page = () => {

  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([null, null])
  const [data, setData] = useState(null);
  const { t, i18n } = useTranslation()

  const getData = useCallback(async (export_data) => {
    console.log(`getData`, dateRange, dateRange?.[0] && dateRange?.[1])
    if (dateRange?.[0] && dateRange?.[1]) {
      const params = { from: new Date(dateRange?.[0]).getTime(), to: new Date(dateRange?.[1]).getTime() }
      const queryParams = `?${Object.keys(params)?.map(key => `${key}=${params[key]}`).join(`&`)}`
      console.log({ params, queryParams })
      const [success, newdata] = await REST.GET(`question_evaluation${queryParams}${export_data ? `&export=1` : ``}`, { download: export_data })
      if (!success) toastError(`${t(`general.error`)} ${t(`general.loading_data`)}`)
      if (export_data) return toastSuccess(`${t(`question_evaluation.export`)} ${t(`question_evaluation.successful`)}`)
      setData(newdata)
    }
  }, [dateRange])

  // useEffect(() => {
  //   getData()
  // }, [])

  useEffect(() => {
    console.log(`date range changed`, dateRange)
    getData()
  }, [dateRange])

  // if (!data)
  //   return <Skeleton />

  return <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 4
    }}
  >
    <Container>

      {/* TITLE */}
      <Box display={`flex`} justifyContent={`space-between`} flexWrap={"wrap"} marginBottom={2}>
        <Typography variant="h2" marginBottom={0}>{t(`question_evaluation.title`)}</Typography>
        <Button
          sx={{ fontSize: 32 }}
          onClick={() => getData(true)}
          startIcon={(
            <SvgIcon sx={{ fontSize: 32 }}>
              <ArrowUpTrayIcon sx={{ fontSize: 32 }} />
            </SvgIcon>
          )}>
          {t(`question_evaluation.export`)}
        </Button>
      </Box>

      <Grid container spacing={3} >
        <Grid>
          <DateRange callback={setDateRange} />
        </Grid>
      </Grid>

      <Grid container spacing={3} >
        <QuestionsTable title={`${t(`question_evaluation.title`)}`} tests={data?.questions} navigate={navigate} {...({ t, i18n })} />
      </Grid>

    </Container>
    {/* <Placeholder /> */}
  </Box >
}

Page.getLayout = (page) => (
  <DashboardLayout>
    {page}
  </DashboardLayout>
);

export default Page;
