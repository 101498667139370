import PropTypes from "prop-types"
import { Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";

const FormCheckbox = ({ name, control }) => {
  return <Controller
    name={name}
    control={control}
    render={({
      field
    }) => {
      return (<Checkbox
        {...field}
        checked={field.value === 1 ? true : false}
        onChange={(event) => {
          field.onChange(event.target.checked ? 1 : 0)
        }}
      />
      )
    }
    }
  />
}

FormCheckbox.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string
}

export default FormCheckbox;