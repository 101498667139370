import axios from "axios";

const baseUrl = import.meta.env.VITE_SERVER_URL || "/";

/**
 * Vytvoření instance Axiosu s předdefinovanými nastaveními.
 */
const apiClient = axios.create({
  baseURL: baseUrl + 'ajax/',
  timeout: 10000, // 10s
  withCredentials: true
});

export default apiClient;