import PropTypes from "prop-types"
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';

import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { useEffect, useState } from 'react';

const customProps = ["isSelected", "isCorrect", "isAnswered"];
// Vzhledové úpravy pro ListItem
const StyledListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== "correct" && prop !== "isSelected" && prop !== "isAnswered"
})(({ theme, isSelected, isAnswered, correct }) => {

  const color = correct ? "success" : "error"

  return ({
    ...(isAnswered && correct && {
      backgroundColor: alpha(theme.palette[color].light, 0.5),
      color: theme.palette[color].dark,
      [".MuiTypography-root"]: {
        fontWeight: "bold"
      },
    }),
    ...(isAnswered && (isSelected && correct === 0) && {
      backgroundColor: alpha(theme.palette[color].main, 0.5),
      color: theme.palette[color].darkest,
      [".MuiTypography-root"]: {
        fontWeight: "bold"
      },
    }),
    //backgroundColor: isCorrect !== undefined
    //  ? (isCorrect ? theme.palette.success.light : theme.palette.error.light)
    //  : (isSelected ? theme.palette.info.light : theme.palette.background.paper),
    padding: '10px 20px',
    marginBottom: '8px',
    transition: 'background-color 0.3s ease', // přidává plynulý přechod mezi barvami
  })
});

const ClosedAnswer = ({ isMultiple, answers, setAnswer, isAnswered, isSuccess }) => {
  const defaultSelected = isAnswered ? answers.filter(i => i.selected).map(i => i.id) : [];
  const [selected, setSelected] = useState(defaultSelected);

  const UncheckedIcon = isMultiple ? CheckBoxOutlineBlankRoundedIcon : RadioButtonUncheckedRoundedIcon;
  const CheckedIcon = isMultiple ? CheckBoxRoundedIcon : RadioButtonCheckedRoundedIcon;

  useEffect(() => {
    setAnswer(selected);
  }, [selected, setAnswer, isMultiple]);

  const handleChange = ({ id }) => {
    if (!isMultiple)
      return setSelected([id]);

    setSelected(prev => {
      if (prev.includes(id)) {
        return prev.filter(i => i !== id);
      } else {
        return [...prev, id];
      }
    });
  }

  return (
    <List component="nav">
      {answers?.map(({ id, description, correct, success, selected: aSelected }) => {

        const isSelected = selected.includes(id);
        // IsCorrect pouze pokud je korektní nebo pokud je to špatně a není korektní
        const isCorrect = isSuccess ? (correct ? 1 : undefined) : correct;

        return (
          <StyledListItem
            key={id}
            onClick={() => handleChange({ id })}
            isSelected={isSelected}
            //isCorrect={isCorrect}
            correct={correct}
            isAnswered={isAnswered}
            selected={!isAnswered && isSelected}
          >
            <ListItemIcon>
              {isSelected ? <CheckedIcon color={isAnswered && isCorrect ? "success" : "primary"} /> : <UncheckedIcon />}
            </ListItemIcon>
            <ListItemText primary={description} />
          </StyledListItem >
        )
      })}
    </List>
  )
}

ClosedAnswer.propTypes = {
  answers: PropTypes.array,
  isMultiple: PropTypes.any,
  setAnswer: PropTypes.func,
  isAnswered: PropTypes.bool,
  isSuccess: PropTypes.bool
}



export default ClosedAnswer;