import * as MaterialDesignIcons from '@mdi/js';
// import Icon as IconMDI from '@mdi/react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';
import * as Heroicons from '@heroicons/react/24/solid';

export const Icon = ({
    children,
    path, //svg path to be rendered
    mdi, // type mdi = material design icons
    heroicons = true, // type heroicons
    small = true, // size S
    medium, // size M
    large, // size L 
}) => {

    const getIcon = (icon_name) => {
        try {
            if (mdi)
                return MaterialDesignIcons[icon_name.trim()];
            if (heroicons)
                return Heroicons[icon_name.trim()];
        } catch (e) {
            return null;
        }
    }

    const fontSize = (small ? `small` : (medium ? `medium` : (large ? `large` : `small`)))

    //string name defining SVG path
    if (path && typeof path === `string`) {
        const PathComponent = getIcon(path) //finding an icon by its name
        if (!PathComponent) return null; // returning null if the icon is not found
        return <SvgIcon fontSize={fontSize}>
            <PathComponent />
        </SvgIcon>
    }

    return <SvgIcon fontSize={fontSize}>
        {children || path}
    </SvgIcon>
}

Icon.propTypes = {
    children: PropTypes.func,
    path: PropTypes.string,
    mdi: PropTypes.bool,
    heroicons: PropTypes.bool,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    large: PropTypes.bool
};