import PropTypes from "prop-types"
import { CloseRounded } from '@mui/icons-material'
import { Box, SvgIcon, Typography } from '@mui/material'

const Error = ({ heading, description, error }) => {
  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <SvgIcon sx={{ color: "error.main", fontSize: "3rem" }}>
        <CloseRounded />
      </SvgIcon>
      <Typography variant="h3">{heading}</Typography>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="caption">{error?.message}</Typography>
    </Box>)
}

Error.propTypes = {
  description: PropTypes.any,
  error: PropTypes.any,
  heading: PropTypes.any
}

export default Error