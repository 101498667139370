/**
 * Kontext pro globální stav zobrazení fullscreen medií
 */
import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

export const FullscreenContext = createContext();

export function useFullscreen() {
  return useContext(FullscreenContext);
}

export function FullscreenProvider({ children }) {
  const [data, setData] = useState(null)

  const open = (data) => {
    console.log(`[fullscreen] open`, data)
    setData(data)
  }

  const close = () => setData(null)

  const contextValue = {
    data,
    open,
    close
  };

  return (
    <FullscreenContext.Provider value={contextValue}>
      {children}
    </FullscreenContext.Provider>
  );
}

FullscreenProvider.propTypes = {
  children: PropTypes.element
}
