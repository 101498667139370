import PropTypes from "prop-types"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import REST from "../utils/rest";
import { useTranslation } from "react-i18next";

const ImageWithFallback = ({ image }) => {
  const [data, setData] = useState(image);
  const [hasError, setHasError] = useState(false);
  const { t, i18n } = useTranslation()


  useEffect(() => {
    setHasError(false);
    setData(image);
  }, [image])

  const handleError = (error, err) => {
    console.error("ImageWithFallback - handleError:", error, err);
    setHasError(true);
  };

  if (hasError) {
    return (
      <Box
        sx={{
          marginX: "auto",
          minWidth: { xs: 200, md: 300 },
          maxWidth: { xs: 200, md: 300 },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Box
          sx={{
            margin: "auto",
            padding: { xs: 2, md: 3 },
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: '#f5f5f5', // Optional: provide a background color
            borderRadius: 2,
            flex: 1
          }}
        >
          <Typography>{t(`errors.image_did_not_load`)}</Typography>
          <ErrorOutlineIcon fontSize="large" color="error" />
        </Box>
        <Typography align="center" variant="caption">{data.name}</Typography>
      </Box>
    );
  }

  return (
    <Box
      component="img"
      alt={data.name}
      onError={handleError}
      sx={{
        margin: "auto",
        minWidth: { xs: '90%', md: 550 },
        maxWidth: { xs: 400, md: 400 },
      }}
      src={REST.URL(`content/${data.url}`)}
    />
  );
};

ImageWithFallback.propTypes = {
  image: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string
  })
}

export default ImageWithFallback;