import { Box, Tab, Tabs } from "@mui/material"
import { useState } from "react"

const TabLayout = ({ tabs }) => {

    const [value, setValue] = useState(0)

    return <>
        <Box sx={{ padding: 1, width: `100%` }}>
            <Tabs value={value} onChange={(event, newValue) => setValue(newValue)} variant="scrollable">
                {tabs?.map((tab, index) => <Tab key={index} label={tab.name} sx={{ fontSize: 16 }} />)}
            </Tabs>
        </Box>

        {tabs?.map((tab, index) => value === index && tab.component)}
    </>
}

export default TabLayout;