const getDirtyValuesForArray = (dirtyValues, values) => {
  try {
    const result = dirtyValues.map((value, index) => getDirtyValues(value, values?.[index])).filter(Boolean);
    return result;
  } catch (e) {
    console.error("getDirtyValuesForArray catch error:", e);
    return [];
  }
}

export function getDirtyValues(dirtyFields, values) {
  try {
    //console.group(values);
    if (Object.values(dirtyFields).filter(Boolean).length === 0) {
      //console.groupEnd();
      return undefined;
    }

    // ! WORKAROUND: z neznámého důvodu je v některých situacích `correct` v dirty jako false,
    // ! což vede k tomu že se nezahrne do výsledků, tato podmínka zabezpečí že se nový řádek zahrne celý.
    if (values?.updated)
      return values;


    const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
      //console.log('key', key)
      // Unsure when RFH sets this to `false`, but omit the field if so.
      if (!dirtyFields[key]) {
        //console.log("getDirtyValues / reduce / return prev", { key, prev });
        return prev;
      }

      const prepare = {
        ...prev,
        ...(values?.id && { id: values.id }), // Pokud zde existuje ID tak jej přidám protože je většinou potřeba
        ...(typeof values?.field !== "undefined" && { field: values.field }), // Pokud zde existuje field tak jej přidám protože je potřeba v dropdownu

      };

      //console.log('prepare', prepare)
      let result = null;

      if (Array.isArray(dirtyFields[key])) {
        result = getDirtyValuesForArray(dirtyFields[key], values[key]);
        if (result.length === 0) {
          return prev;
        }

      } else if (typeof dirtyFields[key] === 'object') {
        result = getDirtyValues(dirtyFields[key], values[key]);
        if (result === undefined) {
          return prev;
        }
      } else {
        //console.log("key", { key, "values[key]": values?.[key], values, })
        result = values[key];
      }
      return {
        ...prepare,
        [key]: result
      }

    }, {});

    //console.groupEnd();

    if (Object.keys(dirtyValues).length === 0)
      return undefined;

    return dirtyValues;
  } catch (e) {
    console.error("error", e)
  }
}