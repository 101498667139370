import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, Radio, FormControlLabel, Typography, Box } from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


function SelectDialog({ open = false, onClose, currentIndex, onSave, initialOptions = [], removeOption }) {
  const [options, setOptions] = useState(initialOptions);
  const { t, i18n } = useTranslation()


  const handleAddOption = () => {
    setOptions([...options, { new_id: Date.now(), text: '', correct: false, field: currentIndex }]);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index].text = value;
    setOptions(newOptions);
  };

  const handleRemoveOption = (index) => {
    removeOption(options[index]);
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  /* const handleCorrectChange = (index) => {
    const newOptions = options.map((option, idx) => ({
      ...option,
      correct: idx === index,
    }));
    setOptions(newOptions);
  }; */

  const handleSave = () => {
    onSave(options);
    onClose();
  };

  const isEmpty = options.length == 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{initialOptions.length ? t(`question_editor.edit_selection`) : t(`question_editor.add_selection`)}</DialogTitle>
      <DialogContent sx={{ p: 4, pt: "2px !important", minWidth: "300px", minHeight: "100px", display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
        {isEmpty ? <Typography variant="body1">{t(`question_editor.add_selection_options`)}</Typography> : ""}
        {options.map((option, index) => (
          <Box key={option.id} display="flex" alignItems="center">

            <TextField
              value={option.text}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              label={`${t(`question_editor.option`)} ${index + 1}`}
              fullWidth
            />
            <IconButton color="error" onClick={() => handleRemoveOption(index)}>
              <RemoveCircle />
            </IconButton>
          </Box>
        ))}
        <Button variant="contained" onClick={handleAddOption} fullWidth>{t(`question_editor.add_option`)}</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t(`general.cancel`)}
        </Button>
        <Button onClick={handleSave} color="primary">
          {t(`general.save`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectDialog;
