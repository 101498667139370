import PropTypes from 'prop-types'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import LiveIndicator from './LiveIndicator'
import { useTranslation } from 'react-i18next';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Link } from 'react-router-dom';

const Header = ({ currentNumber, totalQuestions, totalLives, remainingLives, toggleDrawer }) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ minWidth: "100%", padding: { sm: 2, lg: 2 } }}>

      <Box sx={{ display: "flex", minWidth: "100%", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ marginRight: 2 }} LinkComponent={Link} to="/">
            <ArrowBackRoundedIcon />
          </IconButton>
          <Typography variant="h5">{t("general.question")} {currentNumber} {t("general.from")} {totalQuestions}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }} gap={2}>
          <LiveIndicator total={totalLives} remaining={remainingLives} />
          <IconButton
            aria-label="více"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={toggleDrawer}
          >
            <LibraryBooksRoundedIcon />
          </IconButton>
        </Box>
      </Box>

      <Divider sx={{ marginY: 1 }} />

    </Box>
  )
}

Header.propTypes = {
  currentNumber: PropTypes.number,
  remainingLives: PropTypes.number,
  totalLives: PropTypes.number,
  totalQuestions: PropTypes.number
}

export default Header