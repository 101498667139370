import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import { Box, Button, Container, SvgIcon, Typography } from '@mui/material';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import ErrorPage from './error_page'

const Page = () => <ErrorPage img={"/assets/errors/error-404.png"} title={`404: ${t(`errors.404`)}`} />

// const Page = () => (
//   <>
//     <Box
//       component="main"
//       sx={{
//         alignItems: 'center',
//         display: 'flex',
//         flexGrow: 1,
//         minHeight: '100vh',
//       }}
//     >
//       <Container maxWidth="md">
//         <Box
//           sx={{
//             alignItems: 'center',
//             display: 'flex',
//             flexDirection: 'column'
//           }}
//         >
//           <Box
//             sx={{
//               mb: 3,
//               textAlign: 'center'
//             }}
//           >
//             <img
//               alt="Under development"
//               src="/assets/errors/error-404.png"
//               style={{
//                 display: 'inline-block',
//                 maxWidth: '100%',
//                 width: 400
//               }}
//             />
//           </Box>
//           <Typography
//             align="center"
//             sx={{ mb: 3 }}
//             variant="h3"
//           >
//             404: {t(`errors.404`)}
//           </Typography>
//           {/* <Typography
//             align="center"
//             color="text.secondary"
//             variant="body1"
//           >
//             You either tried some shady route or you came here by mistake.
//             Whichever it is, try using the navigation
//           </Typography> */}
//           <Button
//             component={Link}
//             to="/"
//             startIcon={(
//               <SvgIcon fontSize="small">
//                 <ArrowLeftIcon />
//               </SvgIcon>
//             )}
//             sx={{ mt: 3 }}
//             variant="contained"
//           >
//             {t(`general.back_to_homepage`)}
//           </Button>
//         </Box>
//       </Container>
//     </Box>
//   </>
// );

export default Page;
