import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material'
import StudyMaterial from "./TestIntro";
import { LibraryBooksRounded } from '@mui/icons-material';

const StudyMaterialDrawer = ({ isDrawerOpen, setDrawerOpen, test, confirm, intro, toggleDrawer }) => {
  return (
    <Drawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)} anchor="right">
      <Box sx={{ width: '400px' }}>
        <Box sx={{ minWidth: "100%", padding: { sm: 2, lg: 2 } }}>
          <Box sx={{ display: "flex", minWidth: "100%", justifyContent: "end" }}>
            <Box sx={{ display: "flex", alignItems: "center" }} gap={2}>
              <IconButton
                aria-label="více"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={toggleDrawer}
              >
                <LibraryBooksRounded />
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{ marginY: 1 }} />
        </Box>
        <StudyMaterial test={test} intro={intro} confirm={confirm} />
      </Box>
    </Drawer>
  )
}

export default StudyMaterialDrawer