import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { useEffect } from 'react';

/**
 * Chráněná routa která uživatele přesměruje na login stránku pokud není přihlášený
 */
const ProtectedRoute = ({ component }) => {
  const { currentUser, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser && !isLoading) {
      navigate("/login");
    }
  }, [])

  if (!currentUser)
    return null;

  return component;
}

ProtectedRoute.propTypes = {
  component: PropTypes.element
}

export default ProtectedRoute;