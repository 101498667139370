import PropTypes from "prop-types"
import { Backdrop, Box, CircularProgress, Fade } from '@mui/material'
import useEventListener from "../hooks/useEventListener"
import { useState } from "react";

const Loader = ({ loading: _loading, delay = true }) => {
  const [stateLoading, setLoading] = useState();

  const loading = _loading ?? stateLoading;
  useEventListener('fetch_loader', ({ detail }) => {
    setLoading(detail);
  })
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: (loading && delay) ? "300ms" : "0ms"
      }}
      unmountOnExit
    >
      <Backdrop open sx={{ color: '#fff', zIndex: 999999 }}>
        <Box sx={{ display: "flex" }}>
          <CircularProgress color="inherit" />
        </Box>
      </Backdrop>

    </Fade>
  )
}

Loader.propTypes = {
  loading: PropTypes.bool,
  delay: PropTypes.bool
}

export default Loader