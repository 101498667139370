import { useEffect } from "react";
import MapPage from "./MapPage.jsx";
import './styles/general.scss'


const RTLS = () => {

  //add rtls class to the body when mounted
  document.body.classList.add('rtls')
  useEffect(() => {
    return () => {
      document.body.classList.remove('rtls');
    }
  }, []);

  return <>
    <MapPage />
  </>
}

RTLS.getLayout = (page) => page

export default RTLS;