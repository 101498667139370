import PropTypes from "prop-types"
import { Box, Typography } from '@mui/material';
import OrderAnswer from "./OrderAnswer";
import ClosedImgAnswer from "./ClosedImgAnswer";
import SliderAnswer from "./SliderAnswer";
import DropdownAnswer from "./DropdownAnswer";
import ClosedAnswer from "./ClosedAnswer";
import { useTestContext } from "../../../contexts/TestContext";
import ImageWithFallback from "../../../components/ImageWithFallback";
import { useFullscreen } from "../../../contexts/FullscreenContext";
import REST from "../../../utils/rest";

// TODO: ošetření proti odeslání prázdné odpovědi
// TODO: ošetřit pokud se vrátí "Test not found"

const Question = ({ currentQuestion, progress }) => {
  const { setAnswer, answer, isAnswered, isSuccess } = useTestContext();
  const { id, question, image, answers, qtype: type, multiple } = currentQuestion;

  const AnswerComponents = {
    dropdown: DropdownAnswer,
    closed: ClosedAnswer,
    closedimg: ClosedImgAnswer,
    order: OrderAnswer,
    range: SliderAnswer,
  };

  const AnswerComponent = AnswerComponents[type];
  const fullscreen = useFullscreen()

  const openItem = (rec) => {
    let tag = `img`;
    console.log(`open item`, { rec, tag })
    fullscreen.open({
      [tag]: REST.URL(`content/${rec?.url}`)
    })
  }

  return (
    <>

      {/* Obrázek */}
      {image && (
        <div onClick={() => openItem(image)}
          style={{ display: 'flex', justifyContent: 'center' }}>
          <ImageWithFallback image={image} />
        </div>
      )}
      {/* Aktuální otázka */}
      {type !== "dropdown" && question && <Typography variant="h5" textAlign="center" mb={3} px={2}>
        {question}
      </Typography>}

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

        {/*<Typography variant="body1" textAlign="center" color="GrayText">{instruction}</Typography>*/}

        {/* Seznam odpovědí */}
        <Box sx={{ width: { lg: "80%", md: "90%", sm: "95%", xs: "95%" } }}>
          {AnswerComponent &&
            <AnswerComponent
              id={id}
              question={question}
              answers={answers}
              selected={answer}
              progress={progress}
              setAnswer={setAnswer}
              isMultiple={multiple}
              isAnswered={isAnswered}
              isSuccess={isSuccess}
            />
          }
        </Box>
      </Box>

    </>
  )
}

Question.propTypes = {
  correctId: PropTypes.number,
  currentQuestion: PropTypes.object,
  selectAnswer: PropTypes.func,
  step: PropTypes.string,
  progress: PropTypes.object
}


export default Question