import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTestContext } from "../contexts/TestContext"
//import { getQuestion, getTest } from "../services/questionService.mock";
import { evaluateAnswer, getNextQuestion, getTest } from "../services/test.service";
import { toast } from "react-hot-toast";
import { useTranslation } from 'react-i18next';


const useTestLogic = () => {
  const { t } = useTranslation();
  const {
    test, setTest,
    currentQuestion, setCurrentQuestion,
    loading, setLoading,
    progress, setProgress,
    setError,
    answer, setAnswer
  } = useTestContext();

  const { testId } = useParams();

  useEffect(() => {
    // Workaround - pokud je typ range tak nastavím jako výchozí hodnotu min
    if (currentQuestion?.qtype === "range")
      setAnswer([currentQuestion?.answers?.[0]?.min]);
    else
      setAnswer([])
  }, [currentQuestion?.id])

  const reset = () => {
    setTest(null);
    setCurrentQuestion(null);
    setProgress(null);
    setLoading(true);
    //setSelectedAnswer([]);
    setError(null);
  }

  useEffect(() => {
    if (!test) {
      setTimeout(async () => {
        try {
          const { test, question, progress } = await getTest(testId);

          if (question)
            question.image = question?.files?.[0];

          if (test) setTest(test);
          if (progress) setProgress(progress);
          if (question) setCurrentQuestion(question);

          console.log('"loading false"', "loading false");

          setLoading(false);
        } catch (error) {
          let errorText = "";
          const status = error?.response?.status;

          // Timeouted
          if (error?.code === "ECONNABORTED")
            errorText = t("errors.failed_to_load") + ". (timeouted)";


          if (!errorText)
            switch (status) {
              case 404:
                errorText = t("errors.test_404");
                break;
              case 500:
                errorText = t("errors.server_error");
                break;
              default:
                if (error?.response?.data?.message)
                  errorText = error.response.data.message;
                else if (status)
                  errorText = t(`errors.unknown_error`) + ` - ${status}`;
                else
                  errorText = t(`errors.unknown_error`);
            }

          setError(errorText)
          setLoading(false);
        }

      }, 1000);
    }
  }, [test, setTest, setLoading, testId]);

  /** 
   * fetch evaluation question with selected answer
   */
  const submitAnswer = async () => {
    try {
      if (!currentQuestion?.id) {
        throw new Error("missing question id for submit answer");
      }

      // Pro dropdown kontroluji jestli jsou všechny pole vyplněny
      if (currentQuestion.qtype === "dropdown") {
        const fieldsLength = currentQuestion.answers.length;
        const filledLength = answer.filter(Boolean).length;

        if (fieldsLength > filledLength) {
          toast.error(t("errors.fill_all_fields"))
          return;
        }
      }

      if (!answer || (Array.isArray(answer) && answer.length === 0)) {
        toast.error(t("choose_answer"))
        return;
      }

      setLoading(true);

      const data = await evaluateAnswer(testId, currentQuestion?.id, answer);
      const question = data.data;
      const progress = data.progress;

      if (question)
        question.image = data.data?.files?.[0];

      if (question) setCurrentQuestion(question);
      if (progress) setProgress(progress);

      // TODO: process the response 

      setLoading(false);
    } catch (e) {
      console.error("Submit answer failed. Error:" + e);
      setLoading(false);
      toast.error(t("errors.error_evaluating_test"), { position: "bottom-center", duration: 5000 });
      // TODO: add error toast and options for next step
    }
  };

  /** fetch next question */
  const nextQuestion = async () => {
    setLoading(true);
    const currentId = currentQuestion?.id || 0;
    try {
      const data = await getNextQuestion(testId, currentId);

      if (data.code === "ERR_BAD_REQUEST") {
        if (data?.response?.data?.message)
          setError(data.response.data.message);
        else
          setError(t("errors.unknown_error"));
        return;
      }
      const question = data.data;
      const progress = data.progress;

      if (question)
        question.image = data.data?.files?.[0];

      if (question) setCurrentQuestion(question);
      if (progress) setProgress(progress);

      setLoading(false);
    } catch (e) {
      console.error(t("errors.unable_to_load_next_question"), { testId: 1, currentId }, e);
      setLoading(false);
    }
  };

  return {
    submitAnswer,
    nextQuestion,
    reset
  }
}

export default useTestLogic;