import PropTypes from "prop-types"
import { useEffect, useState } from 'react';
import { Slider, Box, Typography } from '@mui/material';

const SliderQuestion = ({ answers: sliderData, setAnswer }) => {
  const { min, max, success, correct, id, selected } = Array.isArray(sliderData) ? sliderData[0] : sliderData;
  const [value, setValue] = useState(selected || min);

  useEffect(() => {
    setValue(selected || min);
  }, [id, min, selected])

  useEffect(() => {
    setAnswer([value]);
  }, [value, setAnswer])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '80%', margin: '0 auto' }}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          color={(success === false && "error.main") || (success === true && "success.main")}
          sx={{
            textDecoration: success === false ? "line-through" : ""
          }}>
          {value}
        </Typography>
      </Box>
      <Slider
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        valueLabelDisplay="off"
        disabled={typeof correct !== "undefined"}
        step={1}
        style={{
          color: '#1976d2', // Primární barva pro slider
          height: '8px'     // Tloušťka slideru
        }}
        marks={[
          { value: min, label: min.toString() },
          { value: max, label: max.toString() },
        ]}
      />
      {correct && !success && <Typography align="center" variant="h4" gutterBottom color="success.main" >
        {correct}
      </Typography>}
    </Box>
  );
};

SliderQuestion.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  })),
  sliderData: PropTypes.any
}

export default SliderQuestion;
