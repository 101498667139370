import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as locale from 'date-fns/locale';
import { Card, CardContent, SvgIcon, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWidth } from '../hooks/useWidth';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useAuth } from '../contexts/AuthContext';


const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
}));

const startOfWeek = (date) => {
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
};

const endOfWeek = (date) => {
    const start = startOfWeek(date);
    return new Date(start.setDate(start.getDate() + 6));
};

const startOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1);

const endOfMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);

const adjustMonth = (val, adjust) => new Date(val.setMonth(val.getMonth() + adjust))

const adjustWeek = (val, adjust) => new Date(val.setDate(val.getDate() + (adjust * 7)))

export default function CustomDateRangePicker({ callback }) {
    const [value, setValue] = useState([startOfMonth(new Date()), endOfMonth(new Date())]);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const { t, i18n } = useTranslation()
    const width = useWidth()
    const { currentUser } = useAuth()

    useEffect(() => {
        console.log(`CustomDateRangePicker callback`, value)
        if (callback) callback(value)
    }, [value])

    useEffect(() => {
        console.log(`CustomDateRangePicker callback`, value)
        if (callback) callback(value)
    }, [])

    const valueSwitchMonth = (adj) => {
        let newstart = adjustMonth(value[0], adj)
        setValue([startOfMonth(newstart), endOfMonth(newstart)])
    }

    const valueSwitchWeek = (adj) => {
        let newstart = adjustWeek(value[0], adj)
        setValue([startOfWeek(newstart), endOfWeek(newstart)])
    }

    const shortcuts = [
        {
            label: `${t(`date_range_picker.this`)} ${t(`date_range_picker.week`).toLowerCase()}`,
            onClick: () => {
                const today = new Date();
                setValue([startOfWeek(today), endOfWeek(today)]);
            },
        },
        {
            label: `${t(`date_range_picker.last`)} ${t(`date_range_picker.week`).toLowerCase()}`,
            onClick: () => {
                const lastWeek = new Date(new Date().setDate(new Date().getDate() - 7));
                setValue([startOfWeek(lastWeek), endOfWeek(lastWeek)]);
            },
        },
        {
            label: `${t(`date_range_picker.this`)} ${t(`date_range_picker.month`).toLowerCase()}`,
            onClick: () => {
                const today = new Date();
                setValue([startOfMonth(today), endOfMonth(today)]);
            },
        },
        {
            label: `${t(`date_range_picker.last`)} ${t(`date_range_picker.month`).toLowerCase()}`,
            onClick: () => {
                const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));
                setValue([startOfMonth(lastMonth), endOfMonth(lastMonth)]);
            },
        },
    ];

    const isWithinRange = (date) => {
        if (!value[0] || !value[1]) return false;
        return date > value[0] && date < value[1];
    };

    const Day = (props) => {
        const { day, ...other } = props;
        const isSelectedStart = day.toDateString() === value[0]?.toDateString();
        const isSelectedEnd = day.toDateString() === value[1]?.toDateString();
        const highlighted = isSelectedStart || isSelectedEnd || isWithinRange(day);

        return (
            <CustomPickersDay
                {...other}
                day={day}
                isSelected={highlighted}
                style={{
                    borderRadius: isSelectedStart ? '50% 0 0 50%' : isSelectedEnd ? '0 50% 50% 0' : '0',
                }}
            />
        );
    };

    const viewedMonth = currentMonth;
    const viewedMonthBefore = new Date(new Date(currentMonth).setMonth(currentMonth?.getMonth() - 1));
    const viewedMonthAfter = new Date(new Date(currentMonth).setMonth(currentMonth?.getMonth() + 1));

    console.log({ viewedMonth, viewedMonthAfter })

    const changeMonthView = (month, adjust = 0) => {
        const newMonth = new Date(month.setMonth(month.getMonth() + adjust))
        console.log({ month, newMonth, adjust })
        setCurrentMonth(newMonth)
    }

    // return (<Card>
    //     <CardContent sx={{ padding: 2 }}>
    //         {/* <DateRange callback={setDateRange} /> */}

    //         <LocalizationProvider dateAdapter={AdapterDateFns} localeText={cs}>

    //             <Grid container spacing={0}>
    //                 <Grid item xs={12} md={5}>
    //                     <DateCalendar
    //                         sx={{ marginRight: 3 }}
    //                         value={value[0]}
    //                         displayWeekNumber
    //                         showDaysOutsideCurrentMonth
    //                         // referenceDate={viewedMonth}
    //                         // defaultCalendarMonth={viewedMonth}
    //                         // onMonthChange={(month) => changeMonthView(month, 0)}
    //                         onChange={(date) => {
    //                             if (!value[0] || value[1]) {
    //                                 setValue([date, null]);
    //                             } else if (date > value[0]) {
    //                                 setValue([value[0], date]);
    //                             } else {
    //                                 setValue([date, value[0]]);
    //                             }
    //                         }}
    //                         slots={{
    //                             day: Day,
    //                         }}
    //                     />
    //                 </Grid>
    //                 <Grid item xs={12} md={7}>
    //                     <Box>
    //                         <Typography variant='h6' padding={1} marginTop={1}>{t(`date_range_picker.range`)}</Typography>
    //                         <Box>
    //                             <DatePicker
    //                                 sx={{ margin: 1 }}
    //                                 label={t(`date_range_picker.from`)}
    //                                 value={value[0]}
    //                                 onChange={(date) => setValue([date, value[1]])}
    //                             // slots={{ day: Day }}
    //                             />
    //                             <DatePicker
    //                                 sx={{ margin: 1 }}
    //                                 label={t(`date_range_picker.to`)}
    //                                 value={value[1]}
    //                                 onChange={(date) => setValue([value[0], date])}
    //                             // slots={{ day: Day }}
    //                             />
    //                         </Box>
    //                     </Box>
    //                     <Box marginTop={1}>
    //                         <Typography variant='label' padding={1} marginTop={2}>{t(`date_range_picker.shortcuts`)}</Typography>
    //                         <Box display="flex" flexDirection="row" gap={1}>
    //                             {shortcuts.map((shortcut) => (
    //                                 <Button key={shortcut.label} onClick={shortcut.onClick}>
    //                                     {shortcut.label}
    //                                 </Button>
    //                             ))}
    //                         </Box>
    //                         <Typography variant='label' padding={1} marginTop={1}>{t(`date_range_picker.adjust`)}</Typography>
    //                         <Box display="flex" flexDirection="row" gap={1}>
    //                             <Button onClick={() => valueSwitchWeek(-1)}>
    //                                 - {t(`date_range_picker.week`)}
    //                             </Button>
    //                             <Button onClick={() => valueSwitchWeek(1)}>
    //                                 + {t(`date_range_picker.week`)}
    //                             </Button>
    //                             <Button onClick={() => valueSwitchMonth(-1)}>
    //                                 - {t(`date_range_picker.month`)}
    //                             </Button>
    //                             <Button onClick={() => valueSwitchMonth(1)}>
    //                                 + {t(`date_range_picker.month`)}
    //                             </Button>
    //                         </Box>
    //                     </Box>
    //                 </Grid>
    //             </Grid>
    //         </LocalizationProvider>
    //     </CardContent >
    // </Card >);

    const lang = locale?.[currentUser?.UserLangISOCode]

    return (<Card>
        <CardContent sx={{ padding: 2 }}>
            {/* <DateRange callback={setDateRange} /> */}

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={lang} localeText={lang}>


                <Box display="flex" flexDirection={"column"} gap={2}>
                    <Box display="flex" flexDirection={width === "xs" ? "column" : "row"}>
                        {/* <DateCalendar
                        referenceDate={viewedMonthBefore}
                        defaultCalendarMonth={viewedMonthBefore}
                        onMonthChange={(month) => changeMonthView(month, 1)}
                        onChange={(date) => {
                            if (!value[0] || value[1]) {
                                setValue([date, null]);
                            } else if (date > value[0]) {
                                setValue([value[0], date]);
                            } else {
                                setValue([date, value[0]]);
                            }
                        }}
                        slots={{ day: Day }}
                    /> */}
                        <DateCalendar
                            sx={{ marginRight: 3 }}
                            value={value[0]}
                            displayWeekNumber
                            showDaysOutsideCurrentMonth
                            // referenceDate={viewedMonth}
                            // defaultCalendarMonth={viewedMonth}
                            // onMonthChange={(month) => changeMonthView(month, 0)}
                            onChange={(date) => {
                                if (!value[0] || value[1]) {
                                    setValue([date, null]);
                                } else if (date > value[0]) {
                                    setValue([value[0], date]);
                                } else {
                                    setValue([date, value[0]]);
                                }
                            }}
                            slots={{
                                day: Day,
                            }}
                        />
                        {/* <DateCalendar
                        // value={value[1]}
                        referenceDate={viewedMonthAfter}
                        defaultCalendarMonth={viewedMonthAfter}
                        onMonthChange={(month) => changeMonthView(month, -1)}
                        onChange={(date) => {
                            if (!value[0] || value[1]) {
                                setValue([date, null]);
                            } else if (date > value[0]) {
                                setValue([value[0], date]);
                            } else {
                                setValue([date, value[0]]);
                            }
                        }}
                        slots={{ day: Day }}
                    /> */}
                        <Box display="flex" flexDirection="column" /* justifyContent="space-between" */>
                            <Box>
                                <Typography variant='h6' padding={1} marginTop={1}>{t(`date_range_picker.range`)}</Typography>
                                <Box display={"flex"}>
                                    <DatePicker
                                        sx={{ margin: 1 }}
                                        label={t(`date_range_picker.from`)}
                                        value={value[0]}
                                        onChange={(date) => setValue([date, value[1]])}
                                    // slots={{ day: Day }}
                                    />
                                    <DatePicker
                                        sx={{ margin: 1 }}
                                        label={t(`date_range_picker.to`)}
                                        value={value[1]}
                                        onChange={(date) => setValue([value[0], date])}
                                    // slots={{ day: Day }}
                                    />
                                </Box>
                            </Box>
                            <Box marginTop={1}>
                                <Typography variant='label' padding={1} marginTop={2}>{t(`date_range_picker.shortcuts`)}</Typography>
                                <Box display="flex" flexDirection="row" gap={1}>
                                    {shortcuts.map((shortcut) => (
                                        <Button key={shortcut.label} onClick={shortcut.onClick}>
                                            {shortcut.label}
                                        </Button>
                                    ))}
                                </Box>
                                <Typography variant='label' padding={1} marginTop={1}>{t(`date_range_picker.adjust`)}</Typography>
                                <Box display="flex" flexDirection="row" gap={1}>
                                    <Button onClick={() => valueSwitchWeek(-1)}
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <MinusIcon />
                                            </SvgIcon>
                                        )}
                                    >
                                        {t(`date_range_picker.week`)}
                                    </Button>
                                    <Button onClick={() => valueSwitchWeek(1)}
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <PlusIcon />
                                            </SvgIcon>
                                        )}
                                    >
                                        {t(`date_range_picker.week`)}
                                    </Button>
                                    <Button onClick={() => valueSwitchMonth(-1)}
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <MinusIcon />
                                            </SvgIcon>
                                        )}
                                    >
                                        {t(`date_range_picker.month`)}
                                    </Button>
                                    <Button onClick={() => valueSwitchMonth(1)}
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <PlusIcon />
                                            </SvgIcon>
                                        )}
                                    >
                                        {t(`date_range_picker.month`)}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </LocalizationProvider>
        </CardContent>
    </Card>);
}
