import { useMediaQuery, useTheme } from "@mui/material";

export const useWidth = () => {
    const theme = useTheme()
    const keys = [...theme.breakpoints.keys]
    const width = keys.reduce((output, key) => {
        const matches = useMediaQuery(theme.breakpoints.up(key))
        return matches ? key : output
    }, null) ?? 'xs';
    console.log(keys, width);
    return width;
}