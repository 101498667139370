import PropTypes from "prop-types"
import React, { useEffect } from 'react';
import { Select, MenuItem, Typography, FormControl, InputLabel, Chip } from '@mui/material';
import { styled } from '@mui/system';
import { useTestContext } from '../../../contexts/TestContext';
import { useTranslation } from "react-i18next";

const ResponsiveFormControl = styled(FormControl)({
  margin: '0px 8px',
  minWidth: '100px',
  '@media (max-width:600px)': {
    margin: '0px 4px',
  },
});


const DropdownQuestion = ({ id, answers, question, isAnswered }) => {
  const defaultSelected = isAnswered ? answers.map(answer => answer.find(option => option.selected)?.id) : [];

  const { t, i18n } = useTranslation()


  useEffect(() => {

  }, [id])

  const [selectedValues, setSelectedValues] = React.useState(defaultSelected);
  const { setAnswer } = useTestContext();

  const handleChange = (index) => (event) => {
    const newValues = [...selectedValues];
    newValues[index] = event.target.value;
    setSelectedValues(newValues);
    setAnswer(newValues)
  };

  const renderQuestion = () => {
    const splitQuestion = question.split('$');
    return splitQuestion.map((part, index) => {
      if (index === splitQuestion.length - 1) {
        return part;
      }
      const currentAnswers = answers[index];
      const correctAnswer = currentAnswers.find(a => a.correct);
      const isCorrect = correctAnswer?.id === selectedValues[index];

      return (
        <React.Fragment key={index}>
          {part}
          <ResponsiveFormControl>
            {correctAnswer && <Chip label={correctAnswer?.description} color="success" sx={{ marginBottom: 1 }} />}
            <Select
              variant="outlined"
              labelId={`select-label-${index}`}
              value={selectedValues[index] || ""}
              onChange={handleChange(index)}
              autoWidth
              displayEmpty
              error={isAnswered && !isCorrect}
              success={isCorrect}
              readOnly={isAnswered}
              className={isAnswered && isCorrect ? "success" : ""}
            >
              <MenuItem disabled value="">
                <em>{t(`general.choose`)}</em>
              </MenuItem>
              {answers[index].map((answer) => (
                <MenuItem key={answer.id} value={answer.id} selected={Boolean(answer)}>
                  {answer.description}
                </MenuItem>
              ))}
            </Select>
          </ResponsiveFormControl>
        </React.Fragment>
      );
    });
  };

  return (
    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: "center" }}>
      {renderQuestion()}
    </Typography>
  );
};

DropdownQuestion.propTypes = {
  id: PropTypes.number,
  answers: PropTypes.array,
  question: PropTypes.object,
  isAnswered: PropTypes.bool
}

export default DropdownQuestion;
