import PropTypes from 'prop-types';
import ChevronUpDownIcon from '@heroicons/react/24/solid/ChevronUpDownIcon';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery
} from '@mui/material';
//import { Logo } from 'src/components/logo';
import { Scrollbar } from '@/components/scrollbar';
// import { items } from './config';
import { SideNavItem } from './side-nav-item';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from '../../components/logo';
import { useAuth } from '@/contexts/AuthContext';
import { useTranslation } from 'react-i18next';

export const SideNav = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { currentUser } = useAuth();
  const { t, i18n } = useTranslation()

  const content = (
    <Scrollbar
      sx={{
        maxHeight: window.innerHeight,
        '& .simplebar-content': {
          height: '100vh'
        },
        '& .simplebar-scrollbar:before': {
          background: 'neutral.400'
        },
        '& .simplebar-placeholder': {
          maxHeight: 0
        },
        '& .simplebar-vertical': {
          display: "none"
        }
      }}
      style={{ maxHeight: 300 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            component={Link}
            to="/"
            sx={{
              display: 'inline-flex',
              height: 32,
              width: "auto"
            }}>

          <Box
            component={Link}
            to="/"
            sx={{
              display: 'inline-flex',
              height: 50, 
              width: "auto" 
            }}
          >
            <img 
              src={"/assets/logo/eduway_logo.png"}
              alt="Eduway Logo" 
              style={{ height: '84px', width: 'auto' }} 
            />
          </Box>
          </Box>
          {/*<Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.04)',
              borderRadius: 1,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
              p: '12px'
            }}
          >
            <div>
              <Typography
                color="inherit"
                variant="subtitle1"
              >
                Devias
              </Typography>
              <Typography
                color="neutral.400"
                variant="body2"
              >
                Production
              </Typography>
            </div>
            <SvgIcon
              fontSize="small"
              sx={{ color: 'neutral.500' }}
            >
              <ChevronUpDownIcon />
            </SvgIcon>
          </Box>*/}
        </Box>
        <Divider sx={{ borderColor: 'neutral.700' }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0
            }}
          >
            {currentUser?.menu?.map((item, index) => {

              if (typeof item === `string`)
                return <Typography key={index} variant="h6" sx={{ marginTop: `16px !important`, padding: 1 }}>{item}</Typography>


              if (item.visible === false) return null;
              const active = (item.path || item.path === ``) ? (pathname === `/${item.path || ``}`) : false;

              //console.log(`rendering_item......`, { active, item, pathname })
              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={index}
                  path={`/${item.path || ``}`}
                  title={item.title}
                  {...({ t, i18n })}
                />
              );
            })}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: 'neutral.700' }} />
        <Box display="flex" justifyContent="center" p={2}>
          <Typography variant="caption" color="GrayText">v0.9.1</Typography>
        </Box>
      </Box>
    </Scrollbar>
  );


  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.800',
            color: 'common.white',
            width: 280
          }
        }}
        variant={open ? "permanent" : "temporary"}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.800',
          color: 'common.white',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};