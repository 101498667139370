import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid, Button, SvgIcon, TextField, Card } from '@mui/material';
import { Layout as DashboardLayout } from '@/layouts/dashboard/layout';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import FileManager from '../components/file-manager';
import FileManagerTable from '../components/file-manager-table';
import { ArrowUpTrayIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { useFileDrop } from '../contexts/FileDropContext';

const SearchBar = ({ onSearch }) => {
  const [search, setSearch] = useState(``)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log(`search`, search)
      if (onSearch) onSearch(search)
    }, 500)

    return () => clearTimeout(timeout)
  }, [search])

  return <TextField
    sx={{ width: `300px` }}
    fullWidth
    label={t(`general.search`)}
    value={search}
    onChange={(e) => setSearch(e.target.value)}
    endIcon={<SvgIcon fontSize="small">
      <MagnifyingGlassIcon />
    </SvgIcon>}
  />
}


const Page = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation()
  const { user, login, logout } = useAuth();
  const fileDrop = useFileDrop()
  const [search, setSearch] = useState(``)

  return <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 4
      }}
    >
      <Container>

        {/* TITLE */}
        <Typography variant="h2" marginBottom={6}>{t(`file_manager.title`)}</Typography>

        <Stack spacing={3} marginBottom={2} direction={"row"} justifyContent={"space-between"}>
          <SearchBar onSearch={setSearch} />
          <Button
            color="primary"
            endIcon={(
              <SvgIcon fontSize="small">
                <ArrowUpTrayIcon />
              </SvgIcon>
            )}
            size="large"
            variant="text"
            onClick={() => fileDrop?.open()}
          >
            {t(`file_manager.upload`)} {t(`file_manager.files`)}
          </Button>
        </Stack>

        <Grid
          container
          // xs={12}
          spacing={3}
          marginBottom={2}
        >

          <Grid xs={12} >
            <Card>
              <FileManagerTable search={search} width={`unset`} deletable={true} /* maxHeight={670} */ />
            </Card>
          </Grid>
        </Grid>
      </Container >
    </Box >
  </>
}

Page.getLayout = (page) => (
  <DashboardLayout>
    {page}
  </DashboardLayout>
);

export default Page;
