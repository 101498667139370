import { Box, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export default function BasicMenu({ button, options }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, option) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(null);
        option?.callback?.()
    };

    return (
        <div>
            <Box
                // id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {button}
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {options?.map((option, index) => <MenuItem key={index} onClick={(e) => handleClose(e, option)}>{option?.title}</MenuItem>)}
            </Menu>
        </div>
    );
}