import { Box, Card, CardContent, Container, Typography, Button, SvgIcon, IconButton, Backdrop, Modal, Dialog, DialogContent, iconClasses } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import ErrorPage from '../../pages/error_page';
import LanguageTabs from './LanguageTabs';
import SaveButton from '../SaveButton';
import { questionTypes } from './constants';
import { useQuestionEditorContext } from '../../hooks/useQuestionEditorContext';
import { useFieldArray, useForm } from 'react-hook-form';
import { setYupLocale, getQuestionSchema } from './questionSchema';
import { getDirtyValues } from '../../utils/form/getDirtyValues';
import TabPanel from '../Tabs/TabPanel';
import QuestionForm from './QuestionForm';
import Loader from "../Loader";
import Error from '../Error';
import { v4 as uuid } from 'uuid';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { QuestionEditorProvider } from '../../contexts/QuestionEditorContext';
import ConfirmDialog from '../ConfirmDialog';
import { useTranslation } from 'react-i18next';

const QuestionEditor = () => {
  const { data, loading, error, clearError, updateQuestion } = useQuestionEditorContext();
  const [saveState, setSaveState] = useState("ready");
  const [tab, setTab] = useState(0);
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState();
  const { t, i18n } = useTranslation()

  const navigate = useNavigate();

  const questionSchema = getQuestionSchema(i18n);

  const { handleSubmit, formState, control, reset, getValues, watch, setValue } = useForm({
    resolver: yupResolver(questionSchema),
    defaultValues: {
      question: data?.question,
      variants: data?.variants
    }
  });

  const { isDirty, dirtyFields, errors } = formState;

  const { fields: variants, append: appendVariant, remove: removeFromVariants } = useFieldArray({ control, name: "variants", keyName: "rhf_id" });
  const { append: appendRemoved } = useFieldArray({ control, name: "removed_variants", keyName: "rhf_id" });


  const addVariant = useCallback((languageId) => {
    let newVariant = {};

    newVariant.language = languageId;
    newVariant.new_id = `new-variant-${uuid()}`;

    appendVariant(newVariant);
    setTab(variants.length);
  }, [variants, appendVariant]);

  const removeVariant = useCallback((index) => {
    const variant = { ...variants[index] };
    if (!variant.new_id)
      appendRemoved(variant);

    removeFromVariants(index);
    toast.success(t("question_editor.variant_successfully_deleted"));
    setTab(index - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [variants, appendRemoved, removeFromVariants])

  useEffect(() => {
    if (data) {
      reset({
        question: data.question,
        variants: data.variants,
      });
    }
  }, [data, reset]);

  // Při změně jazyka se předá nová lokalizace do Yup
  useEffect(() => {
    setYupLocale(i18n);
  }, [i18n, i18n.language])


  const onSubmit = async (values) => {
    try {
      console.log("onSubmt", { dirtyFields, values })
      const data = getDirtyValues(dirtyFields, values);
      console.log('data', data)

      // Přidám do dat question id
      if (!data?.question) {
        data.question = { id: values.question.id };
      }
      setSaveState("loading");
      const result = await updateQuestion(data);
      if (result)
        setSaveState("saved");
      else
        setSaveState("failed");


      setTimeout(() => {
        setSaveState("ready");
      }, 2000);

    } catch (e) {
      console.error("onSubmit failed:", e);
    }
  }

  const onErrors = (errors) => {
    toast.error(t("question_editor.err_saving"));
    console.log("errors", errors);
    console.log('data', getValues())
  }

  if (!data && !loading)
    return <ErrorPage title={t("question_editor.data_missing")} />;
  if (!data && loading) return <Loader loading={loading} delay={false} />;

  const { question, languages } = data;
  const { name, type } = question;

  const activeVariants = variants?.map(v => v.language);

  const tabs = variants.map((v, index) => {
    const currentLang = languages.find(i => i.id === v.language);
    const error = errors?.variants?.[index];
    const sx = {
      padding: 2,
      marginLeft: "24px",
      ...(error && {
        color: "error.main !important",
        backgroundColor: "error.light",
        '&.Mui-selected': {
          color: "red"
        },
      }),
    };
    return { id: currentLang.id, label: currentLang.name, sx };
  })

  const questionType = questionTypes[type];

  const save = handleSubmit((values) => onSubmit(values), onErrors);

  const back = () => {
    // Pokud existují nějaké neuložené změny, nechám si potvrdit že chce odejít bez uložení
    if (isDirty) {
      setConfirmDialog({
        open: true,
        title: t("question_editor.action_unreverseable") + " " + t("question_editor.changes_tobe_lost"),
        text: t("question_editor.leave_editor_without_saving"),
        confirmButtonLabel: t("question_editor.leave_without_saving"),
        confirm: () => navigate(-1)
      })
      return;
    }

    navigate(-1);
  };

  const confirmRemoveVariant = (index) => {
    setConfirmDialog({
      open: true,
      title: t("question_editor.action_unreverseable"),
      text: t("question_editor.variant_delete_confirm"),
      confirmButtonLabel: t("question_editor.delete"),
      confirm: () => removeVariant(index)
    })
  }

  return (
    <Container>
      <Box mt={2} position="sticky" top={0} zIndex={10}>
        <Card>
          <CardContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center" gap={2}>
                <IconButton onClick={back} size="large" ><ArrowBackRoundedIcon /></IconButton>
                <Typography variant='h4'>{name}</Typography>
              </Box>
              <Box gap={2} display="flex">
                <SaveButton disabled={!isDirty} state={saveState} onClick={save} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <LanguageTabs
        currentTab={tab}
        setTab={setTab}
        activeVariants={activeVariants}
        languages={languages}
        tabs={tabs}
        addVariant={addVariant}
        dialogOpen={languageDialogOpen}
        setDialogOpen={setLanguageDialogOpen}
      />

      {variants.length === 0 && <Box sx={{ mt: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>
        <Typography variant="h5">{t(`errors.no_variant`)}</Typography>
        <Button variant="contained" size="large" onClick={() => setLanguageDialogOpen(true)}>{t(`question_editor.add_variant`)}</Button>
      </Box>}

      {variants.map((variant, index) => {
        return <TabPanel key={variant.language} value={tab} index={index}>
          <QuestionForm
            name={`variants.${index}`}
            errors={errors?.variants?.[index]}
            control={control}
            questionType={questionType}
            watch={watch}
            setValue={setValue}
            removeVariant={() => confirmRemoveVariant(index)}
          />
        </TabPanel>
      })}
      <Dialog open={!!error}>
        <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Error
            heading={t("general.error")}
            description={error?.message}
          />
          <Button variant="contained" onClick={() => clearError()}>{t(`general.understood`)}</Button>
        </DialogContent>
      </Dialog>
      <Loader loading={loading} delay={false} />
      {confirmDialog?.open && <ConfirmDialog
        {...confirmDialog}
        onClose={() => setConfirmDialog(null)}
      />}
    </Container >
  )
}

const QuestionEditorContainer = () => {
  const { questionId } = useParams();

  console.log('questionId', questionId)

  return (
    <QuestionEditorProvider questionId={questionId}>
      <QuestionEditor />
    </QuestionEditorProvider>
  );

};

export default QuestionEditorContainer

/*
        <Box mt={2}>
        <Card>
          <CardContent>
            <Box display="flex" flexWrap="wrap">
              <SettingItem
                title="Typ otázky"
                subtitle={questionType?.description || "Vyberte typ otázky"}
                endComponent={
                  <FormSelect
                    name="question.type"
                    placeholder="Vyberte typ"
                    control={control}
                    options={Object.values(questionTypes)}
                    sx={{ minWidth: 150, fontSize: "24px" }}
                    displayEmpty={true}
                  />
                }
                mb={3}
              />

              <SettingItem
                title="Vícenásobná odpověď"
                subtitle={"Otázka může mít více odpovědí"}
                endComponent={<FormSwitch name="question.multi" control={control} />}
                mb={3}
              />

              <SettingItem
                title="Obrázková odpověď"
                subtitle={"Uživatel vybírá správný obrázek"}
                endComponent={<FormSwitch name="question.imageInAnswer" control={control} />}
              />
            </Box>
          </CardContent>
        </Card>
        </Box> 
        */