import PropTypes from "prop-types"
import { Delete, Image } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CardHeader, IconButton } from '@mui/material'
import { useState } from 'react';
import { useFileManager } from "../../contexts/FileManagerContext";
import { useController } from "react-hook-form";
import REST from "../../utils/rest";
import { useTranslation } from "react-i18next";

const QuestionImageCard = ({ name, control }) => {
  const { field } = useController({ name: `${name}.files`, control });
  const { url, name: imageName } = field?.value?.[0] || {};
  const { t, i18n } = useTranslation()


  const fileManager = useFileManager();

  const openFileManager = () => {
    fileManager.open({
      pickOne: true,
      callback: (data) => {
        field.onChange([{
          id: data[0].EXTRA_FILE_ID,
          name: data[0].EXTRA_FILE_NAME,
          mime: data[0].EXTRA_FILE_MIME_TYPE,
          url: data[0].EXTRA_FILE
        }]);
      }
    });
  }

  const removeImage = () => {
    field.onChange([null]);
  }

  return (
    <Card>
      <CardHeader
        title={t(`question_editor.question_image`)}
      />
      <CardContent sx={{ pt: 0 }}>
        {url && <Box
          component="img"
          src={REST.URL(`content/${url}`)}
          alt={imageName}
          maxWidth="100%"
          borderRadius={2}
          border={1}
          borderColor="InactiveBorder"
          onClick={openFileManager}
        />}

        {!url && <Box textAlign="center" mb={1}>
          <Image color="action" sx={{ fontSize: "4rem" }} />
        </Box>}

        <Box p={1} display="flex" gap={1}>
          {!url && <Button variant="contained" fullWidth onClick={() => openFileManager()} color="success">{t(`question_editor.add_image`)}</Button>}
          {url && <>
            <Button variant="contained" fullWidth onClick={() => openFileManager()}>{t(`question_editor.change_image`)}</Button>
            <IconButton title={t(`question_editor.remove_image`)} onClick={() => removeImage()}>
              <Delete color="error" />
            </IconButton>
          </>}
        </Box>

      </CardContent>
    </Card>
  )
}

QuestionImageCard.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string
}

export default QuestionImageCard