import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DashboardPage from './pages/index'
import TestEvaluationPage from './pages/test-evaluation'
import QuestionEvaluationPage from './pages/question-evaluation'
import UserEvaluationPage from './pages/user-evaluation'
import CompaniesPage from './pages/companies'
import CustomersPage from './pages/customers'
import AccountPage from './pages/account'
import SettingsPage from './pages/settings'
import ErrorPage from './pages/404'
import TestPage from './pages/Test'
import PageTable from './pages/Table'
import EditQuestion from './components/QuestionEditor/QuestionEditor'
import EditTestKit from './components/TestKitEditor/TestKitEditor'
import FileManager from './pages/file-manager'
// import menu from "./data/menu.json"
import { useAuth } from '@/contexts/AuthContext';


const Layout = () => {

  const { currentUser } = useAuth();


  return (
    <React.Fragment>
      <Routes>
        <Route index element={DashboardPage.getLayout(<DashboardPage />)} />
        <Route path="/test_evaluation" element={CompaniesPage.getLayout(<TestEvaluationPage />)} />
        <Route path="/question_evaluation" element={CompaniesPage.getLayout(<QuestionEvaluationPage />)} />
        <Route path="/user_evaluation" element={CompaniesPage.getLayout(<UserEvaluationPage />)} />
        <Route path="/companies" element={CompaniesPage.getLayout(<CompaniesPage />)} />
        <Route path="/customers" element={CustomersPage.getLayout(<CustomersPage />)} />
        <Route path="/account" element={AccountPage.getLayout(<AccountPage />)} />
        <Route path="/settings" element={SettingsPage.getLayout(<SettingsPage />)} />
        <Route path="/file_manager" element={SettingsPage.getLayout(<FileManager />)} />

        {currentUser?.menu?.filter(x => x.dial).map(i => {
          // cesta pro RTL by neměla obsahovat lomítko na začátku - ale měla obsahovat lomítko na konci :-)
          return <Route key={i.path} path={i.path} element={PageTable.getLayout(<PageTable key={i.path} func={i.path.replace(/^\/+|\/+$/g, '') + '/'} />)} />
        })}


        <Route path="/test/:testId" element={<TestPage />} />
        <Route path="/edit_question/:questionId" element={<EditQuestion />} />
        <Route path="/edit_test_kit/:testKitID" element={<EditTestKit />} />



        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </React.Fragment>
  )
}

export default Layout