import { centralFetch } from "./central-fetch";
import { toastLoading } from "./toasts";
// import { storageGet, storageSet } from "./local-storage";

export default class REST {

    static fetch({ method, url, body, ...rest }) {
        return new Promise((resolve) => {
            centralFetch({
                method, param: url, loader: true, body, ...rest,
                cb: (res) => {
                    if (res === "failed")
                        return resolve([false, null])

                    if ((res?.Code && res?.Code !== 100) || (res?.code && res?.code !== 100))
                        return resolve([false, res?.Results || res])

                    resolve([true, res?.Results || res])
                },
            });
        })
    }

    // static fetch({ method, url, body, ...rest }) {

    //     //nastaneví request timeoutu
    //     const controller = new AbortController()
    //     let timespan = parseInt(process?.env?.REACT_APP_REQUEST_TIMEOUT) || null;
    //     if (method === `GET`)
    //         timespan = parseInt(process?.env?.REACT_APP_REQUEST_TIMEOUT_GET) || null;

    //     const fetchAbortTimeout = setTimeout(() => {
    //         console.log(`central fetch... abort`, { timespan })
    //         if (!timespan) return;
    //         controller.abort();
    //     }, timespan)

    //     const URL = process.env.REACT_APP_YOUR_URL ? process.env.REACT_APP_YOUR_URL : "/ajax/";

    //     return fetch(URL + url, {
    //         body: body,
    //         mode: "cors",
    //         cache: "no-cache",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             "Access-Control-Allow-Headers": "X-Server-Started, ETag",
    //             "Access-Control-Expose-Header": "X-Server-Started, ETag",
    //         },
    //         method: method,
    //         credentials: "include",
    //         signal: controller.signal
    //     }).then((response) => {

    //         clearTimeout(fetchAbortTimeout)

    //         //DEADLOCK
    //         if (response.status === 503)
    //             throw new Error()

    //         //UNAUTHORISED
    //         if (response.status === 401) {
    //             window?.location?.reload()
    //             throw new Error()
    //         }

    //         const metadata = storageGet("server_metadata");
    //         const serverStarted = response?.headers?.get("X-Server-Started");
    //         const serverVersion = response?.headers?.get("X-Server-Version");

    //         // Vytvořím nové meta podle dat z response a starého meta
    //         const newMetadata = {
    //             ...metadata,
    //             serverVersion: serverVersion,
    //             serverStarted: serverStarted,
    //         };

    //         // Pokud se serverStarted nerovná tak proběhl restart serveru a proto spustím reload stránky
    //         if (+newMetadata?.serverStarted !== +serverStarted) {
    //             storageSet("server_metadata", newMetadata);

    //             // A vyvolám refresh
    //             return window.location.reload();
    //         }

    //         // Data nakonec uložím;
    //         storageSet("server_metadata", newMetadata);

    //         return response?.json();
    //     })
    // }

    static async GET(url, props, callback) {
        //console.log(`GET`, url, callback)
        const response = await this.fetch({ method: `GET`, url, ...props })
        //console.log(`GET`, url, `result`, ...response)
        if (!callback) return response;
        callback(...response)
    }

    static async PUT(url, body, props, callback) {
        const response = await this.fetch({ method: `PUT`, url, body, ...props })
        //console.log(`PUT`, url, `result`, ...response)
        if (!callback) return response;
        callback(...response)
    }

    static async POST(url, body, props, callback) {
        const response = await this.fetch({ method: `POST`, url, body, ...props })
        //console.log(`POST`, url, `result`, ...response)
        if (!callback) return response;
        callback(...response)
    }

    static async DELETE(url, body, props, callback) {
        const response = await this.fetch({ method: `DELETE`, url, body, ...props })
        //console.log(`DELETE`, url, `result`, ...response)
        if (!callback) return response;
        callback(...response)
    }

    static URL(path = ``) {
        const baseUrl = import.meta.env.VITE_SERVER_URL || (window.location.origin + "/");

        return baseUrl + path;
    }
}